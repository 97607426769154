import { autorun, observable, makeAutoObservable } from 'mobx';
import { any } from 'rambda';
import { QueryKey } from 'react-query';
import { RootStore } from 'store';

export class IncomingBillsStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {
      this.updateQuery([
        this.entityType,
        this.rootStore.filtersStore.filtersQuery[this.entityType],
      ]);
    });
  }
  updateQuery = (query: QueryKey[]) => {
    this.queryKey.replace(query);
  };
  entityType = 'incomingBills';
  queryKey = observable.array<QueryKey>([]);
  public newBills = observable.array<string>([], { deep: false });
  public uploadingFiles = observable.array<File>([], { deep: false });
  public processingFiles = observable.array<boolean>([]);

  addNewBillId = (billId: string) => this.newBills.push(billId);

  setProcessingStatusForFile = (idx: number, status: boolean) => {
    this.processingFiles[idx] = status;
  };

  removeUploadingFile = (file: File) => {
    this.uploadingFiles.remove(file);
  };

  addUploadingFiles = (files: File[]) => {
    const concatenatedFiles = this.uploadingFiles.concat(files);
    this.uploadingFiles.replace(concatenatedFiles);
  };

  clearUploadingFiles = () => this.uploadingFiles.clear();
  clearNewBills = () => this.newBills.clear();

  get hasProcessingFiles() {
    return any(Boolean, this.processingFiles);
  }
}
