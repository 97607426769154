import styled from 'styled-components';
import { View } from '../View';

export const Label = styled.label<{ readOnly?: boolean }>`
  display: block;
  margin-bottom: 24px;
`;

export const Control = styled(View)`
  align-self: flex-start;
`;
