import { autorun, observable, makeAutoObservable } from 'mobx';
import { QueryKey } from 'react-query';
import { RootStore } from 'store';

export class TeamStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    autorun(() => {
      this.updateQuery([
        this.entityType,
        this.rootStore.filtersStore.filtersQuery[this.entityType],
      ]);
    });
  }
  updateQuery = (query: QueryKey[]) => {
    this.queryKey.replace(query);
  };
  entityType = 'team';
  queryKey = observable.array<QueryKey>([]);
}
