import { Spinner } from '@monite/ui';
import { init as init18n } from 'i18n';
import React, { Suspense, StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MixpanelProvider } from 'react-mixpanel-browser';
import store from './store/init';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const init = async () => {
  await init18n();
  await store.busStore.init();
  const { default: Inner } = await import('Inner');
  const { default: App } = await import('components/App');
  return (
    <Suspense fallback={<Spinner />}>
      <StrictMode>
        <MixpanelProvider config={{ api_host: 'https://api-eu.mixpanel.com' }}>
          <QueryClientProvider client={queryClient}>
            <Inner store={store}>
              <App />
            </Inner>
          </QueryClientProvider>
        </MixpanelProvider>
      </StrictMode>
    </Suspense>
  );
};

export default init;
