/* eslint-disable no-template-curly-in-string */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import { setLocale } from 'yup';
export const dateLocales: { [key: string]: Locale } = {
  en: enGB,
  de,
};

i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next);

export const errorsLocalize = {
  en: {
    string: {
      required: 'Is a required field',
      email: 'Must contain a valid email address',
      min: 'Must be at least ${min} characters long',
      max: 'May not be more than ${max} characters long',
    },
    mixed: {
      required: 'Is a required field',
      default: 'Is invalid',
    },
    date: {
      min: 'Must be later than ${min}',
      max: 'Must be at earlier than ${max}',
    },
    array: {
      min: 'Must have at least ${min} items',
      max: 'must have less than or equal to ${max} items',
    },
  },
  de: {
    string: {
      required: 'Pflichtfeld',
      email: 'Es muss eine gültige Email hinterlegt werden',
      min: 'Die Eingabe muss mindestens  ${min} Zeichen lang sein',
      max: 'Die Eingabe darf maximal  ${max} Zeichen lang sein',
    },
    mixed: {
      required: 'Pflichtfeld',
      default: 'Ungültig',
    },
    date: {
      min: 'Muss später sein als ${min}',
      max: 'Muss früher sein als ${max}',
    },
    array: {
      min: 'Feld muss mindesten ${min} Einträge haben',
      max: 'Feld darf höchstens ${max} Einträge haben',
    },
  },
};

export const init = async () => {
  await i18n.init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    saveMissing: false,
    fallbackLng: 'en',
    whitelist: ['en', 'de'],
    detection: {
      order: ['localStorage', 'navigator'],
    },
    returnEmptyString: false,
  });
  setLocale(errorsLocalize[i18n.language]);
};

export default i18n;
