import { differenceInMonths, differenceInYears } from 'date-fns';
import startOfMonth from 'date-fns/fp/startOfMonth';
import startOfYear from 'date-fns/fp/startOfYear';
import startOfDay from 'date-fns/startOfDay';
import startOfWeek from 'date-fns/startOfWeek';

const today = new Date();
export const getNewerDate = (period: string) => {
  switch (period) {
    case 'year':
      return startOfYear(today);
    case 'month':
      return startOfMonth(today);
    case 'week':
      return startOfWeek(today, { weekStartsOn: 1 });
    case 'day':
      return startOfDay(today);
    default:
      return '';
  }
};

export const getFmt = (key: string, startDate?: Date, endDate?: Date) => {
  if (key !== 'custom') {
    return {
      year: 'month',
      month: 'day',
      week: 'day',
    }[key];
  }
  if (startDate && endDate) {
    if (differenceInYears(endDate, startDate) > 2) {
      return 'year';
    }
    if (differenceInMonths(endDate, startDate) > 2) {
      return 'month';
    }
    return 'day';
  }
  return 'month';
};
