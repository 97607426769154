import { ReactNode } from 'react';

export interface AvatarProps {
  /** Avatar falls back to the users's initial when no image is provided. Supply a `username` and omit `src` to see what thus looks like. */
  name?: string;
  /** The URL of the Avatar's image */
  src?: string;
  /** Avatar comes in four sizes. In most cases, you'll be fine with `medium` */
  size?: 'xxs' | 'xs2' | 'xs' | 'sm' | 'regular' | 'xl' | 'xxl';
  /** Use the loading state to indicate that the data Avatar need is still loading. */
  loading?: boolean;
  /** The background for initials */
  color?: string;
  /** The color for initials */
  textColor?: string;
  icon?: ReactNode;
  type?: 'square' | 'round' | 'roundCorner';
  border?: string;
}

export const getInitials = (name: string): string => name.charAt(0);
