import { IObservableArray, makeAutoObservable, observable } from 'mobx';
import { includes } from 'rambda';

class TablesStore {
  constructor() {
    makeAutoObservable(this);
  }
  empty = observable({
    incomingBills: false,
    counterparts: false,
    transactions: false,
    reports: false,
    accounting: false,
    products: false,
    invoices: false,
    team: false,
    permissions: false,
  });
  public checked: Record<string, IObservableArray<string>> = {
    incomingBills: observable([]),
    counterparts: observable([]),
    transactions: observable([]),
    invoiceTransactions: observable([]),
    reports: observable([]),
    products: observable([]),
    invoices: observable([]),
    expenses: observable([]),
    accounting: observable([]),
    team: observable([]),
    permissions: observable([]),
    accountant: observable([]),
  };

  setEmpty = (entityType: string, val: boolean) =>
    (this.empty[entityType] = val);

  public onCheck = (entityType: string, id: string) => {
    const state = this.checked[entityType];
    if (includes(id, this.checked[entityType])) {
      state.remove(id);
    } else {
      state.push(id);
    }
  };

  public checkAll = (entityType: string, allIds: readonly string[]) => {
    const state = this.checked[entityType];
    if (!state.length) {
      state.replace(allIds as string[]);
    } else {
      state.clear();
    }
  };

  public clearChecked = (entityType: string) => {
    const state = this.checked[entityType];
    state.clear();
  };
}

export default TablesStore;
