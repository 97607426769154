import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  SelectHTMLAttributes,
} from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { Control, Label } from './styles';
import { Colors } from '../styles/theme';

export interface SelectBaseProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: string;
  addon?: () => ReactNode;
  optionalText?: string;
  inputSize?: 'sm' | 'md' | 'xl';
  postfix?: () => ReactNode;
  labelWidth?: number;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  noPad?: boolean;
}

const Postfix = styled.div`
  width: 140px;
  text-align: right;
`;

const Select = styled.select<{ ref: any }>`
  height: 40px;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  padding: 0 12px;
  background-color: ${({ value, defaultValue }) =>
    value || defaultValue ? Colors.transparent : Colors.gray7};
  color: ${Colors.textBlack};
  border: 1px solid
    ${({ value, defaultValue }) =>
      value || defaultValue ? Colors.gray5 : Colors.transparent};
  &::placeholder {
    color: ${Colors.lightGray};
  }
  &:focus {
    border-color: ${Colors.gray5};
    background-color: ${Colors.transparent};
  }
  font-family: 'Basier Circle', sans-serif;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTFMMTIgMTVMMTYgMTEiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;
  background-position: 99% center;
  &:required:invalid {
    color: ${Colors.textGray};
  }
  ${({ disabled }) => disabled && `color: ${Colors.gray3}`}
`;

// eslint-disable-next-line react/display-name
export const SelectNative = forwardRef<HTMLSelectElement, SelectBaseProps>(
  (props: SelectBaseProps, ref) => {
    const { label, disabled, postfix, value, error } = props;

    return (
      <Label readOnly={disabled}>
        {label && (
          <Typography weight="medium" marginB={8} size="xs">
            {label}:
          </Typography>
        )}
        <Control row={Boolean(postfix)} centerV flex={1}>
          <Select
            disabled={disabled}
            ref={ref}
            {...props}
            value={value || ''}
          />
          {postfix && <Postfix>{postfix()}</Postfix>}
        </Control>
        {error && (
          <Typography paddingV={8} size="xxs" color={Colors.error}>
            {error}
          </Typography>
        )}
      </Label>
    );
  }
);

export default SelectNative;
