import { AccountantStore } from 'apps/accountantAccess/store';
import AccountingStore from 'apps/accounting/store';
import AccountsStore from 'apps/accounts/store';
import { PermissionsStore } from 'apps/permissions/store';
import { TeamStore } from 'apps/team/store';
import { createContext, useContext } from 'react';
import { IncomingBillsStore } from 'apps/bills/store';
import { ExpensesStore } from 'apps/expenses/store';
import { InvoicesStore } from 'apps/invoices/store';
import { ProductsStore } from 'apps/products/store';
import { ReportsStore } from 'apps/reports/store';
import { TransactionsStore } from 'apps/transactions/store';
import CounterpartsStore from 'apps/counterparts/store';
import FiltersStore from 'store/filtersStore';
import ModalStore from 'store/modal';
import TablesStore from 'store/tablesStore';
import NotificationsStore from 'store/notifications';
import UserStore from 'store/user';
import Bus from './bus';
import StatesStore from './states';
import EntityStore from './entity';

export class RootStore {
  accountsStore: AccountsStore;
  entityStore: EntityStore;
  filtersStore: FiltersStore;
  invoicesStore: InvoicesStore;
  incomingBillsStore: IncomingBillsStore;
  expensesStore: ExpensesStore;
  reportsStore: ReportsStore;
  transactionsStore: TransactionsStore;
  productsStore: ProductsStore;
  counterpartsStore: CounterpartsStore;
  modalsStore: ModalStore;
  accountingStore: AccountingStore;
  teamStore: TeamStore;
  accountantStore: AccountantStore;
  permissionsStore: PermissionsStore;
  tablesStore: TablesStore;
  notificationsStore: NotificationsStore;
  userStore: UserStore;
  busStore: Bus;
  statesStore: StatesStore;
  constructor() {
    this.busStore = new Bus(this);
    this.entityStore = new EntityStore(this);
    this.userStore = new UserStore(this);
    this.accountsStore = new AccountsStore(this);
    this.filtersStore = new FiltersStore();
    this.invoicesStore = new InvoicesStore(this);
    this.incomingBillsStore = new IncomingBillsStore(this);
    this.expensesStore = new ExpensesStore(this);
    this.reportsStore = new ReportsStore(this);
    this.transactionsStore = new TransactionsStore(this);
    this.productsStore = new ProductsStore(this);
    this.counterpartsStore = new CounterpartsStore(this);
    this.accountingStore = new AccountingStore(this);
    this.teamStore = new TeamStore(this);
    this.accountantStore = new AccountantStore(this);
    this.permissionsStore = new PermissionsStore(this);
    this.notificationsStore = new NotificationsStore(this);
    this.modalsStore = new ModalStore();
    this.tablesStore = new TablesStore();
    this.statesStore = new StatesStore(this);
  }
}

export const StoreContext = createContext<RootStore>({} as RootStore);
export const useStores = (): RootStore => useContext(StoreContext);
export const useStore = <T extends keyof RootStore>(store: T): RootStore[T] =>
  useContext(StoreContext)[store];
