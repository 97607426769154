import { UilTimes } from '@iconscout/react-unicons';
import React, { ReactNode } from 'react';
import { Typography } from '../Typography';
import { TopBar, CloseBtn } from './styles';
import { Colors } from '../styles/theme';

interface ModalTopProps {
  title?: ReactNode;
  onClose?: () => void;
}

const ModalTop = ({ title, onClose }: ModalTopProps) => {
  return (
    <TopBar>
      <Typography weight="medium" align="center">
        {title}
        {onClose && (
          <CloseBtn onClick={onClose}>
            <UilTimes color={Colors.textGray} />
          </CloseBtn>
        )}
      </Typography>
    </TopBar>
  );
};

export default ModalTop;
