import styled from 'styled-components';
import { Colors } from '../styles/theme';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

export const Item = styled.label<{ $checked: boolean; $width?: string }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 10px 10px;
  cursor: pointer;
  padding: 0 15px;
  background: ${({ $checked }) =>
    $checked ? Colors.blue : Colors.transparent};
  border: 1px solid ${Colors.gray4};
  flex-grow: 0;
  flex-basis: ${({ $width }) => `calc(${$width} - 20px)`};
  div {
    white-space: nowrap;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
  }
`;
