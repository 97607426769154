import React, {
  forwardRef,
  ReactNode,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import useOnClickOutside from '../hooks/useOnClickOutside';
import 'simplebar/dist/simplebar.min.css';
import './simplebar.css';
import { CSSTransition } from 'react-transition-group';
import { Wrapper, ToggleWrapper, SPopup } from './styles';
import { useMountedState } from 'react-use';

export type PopupPosition =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'center'
  | 'bottomRight'
  | 'topLeft'
  | 'topRight';

interface SelectPopupProps {
  animation?: 'slide' | 'fade';
  position?: PopupPosition;
  width?: number;
  toggle: (
    toggleShown: (e: SyntheticEvent) => void,
    isShown: boolean
  ) => ReactNode;
  content: (hide: () => void) => ReactNode;
  onClose?: () => void;
}

const Popup = forwardRef<
  HTMLDivElement,
  {
    width?: number;
    position?: PopupPosition;
    children: ReactNode;
    hide: () => void;
  }
>(({ width, position = 'bottom', children, hide }, ref) => {
  const onClose = () => {
    setTimeout(() => {
      hide();
    }, 200);
  };
  useOnClickOutside(ref, onClose);
  return (
    <SPopup width={width} position={position} ref={ref}>
      {children}
    </SPopup>
  );
});

const DropMenu = ({
  animation = 'slide',
  width,
  position = 'bottom',
  content,
  toggle,
  onClose,
}: SelectPopupProps) => {
  const isMounted = useMountedState();
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isShown, setIsShown] = useState(false);
  const hide = () => {
    if (isMounted()) {
      setIsShown(false);
      onClose && onClose();
    }
  };
  const toggleShown = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isMounted()) {
      setIsShown(!isShown);
    }
  };
  const stopClick = (e: SyntheticEvent) => e.stopPropagation();
  return (
    <Wrapper onClick={stopClick}>
      <ToggleWrapper>{toggle(toggleShown, isShown)}</ToggleWrapper>
      <CSSTransition
        classNames={animation}
        timeout={300}
        unmountOnExit
        in={isShown}
        nodeRef={nodeRef}
      >
        <Popup ref={nodeRef} width={width} position={position} hide={hide}>
          {content(hide)}
        </Popup>
      </CSSTransition>
    </Wrapper>
  );
};

export default DropMenu;
