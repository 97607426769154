import psd2Api from 'api/psd2';
import { ClientStateKey, State } from 'const';
import { differenceInHours, parseISO } from 'date-fns/fp';
import {
  autorun,
  observable,
  when,
  flow,
  makeAutoObservable,
  action,
} from 'mobx';
import { RootStore } from 'store';
import { filter, find, path, compose, sum, map, propEq, prop } from 'rambda';
import isDocumentVisible from 'utils/isDocumentVisible';
import { isProduction } from 'utils/env';
import accountsApi from 'apps/accounts/api';
import { BankAccount, Psd2Connection } from 'apps/accounts/types';
import { isLoaded, isLoading, isPending } from 'helpers/states';

const pollDelay = 30_000;

class AccountsStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () =>
        this.rootStore.userStore?.isAuth &&
        Boolean(this.rootStore.userStore?.activeEntity),
      () => {
        if (isProduction) {
          this.pollAccounts();
        }
        this.pollPsd2();
        this.fetchPsd2Statuses();
      }
    );

    makeAutoObservable(this, {
      setDefaultAccount: action,
      setActiveAccount: action,
    });
    autorun(() => {
      if (this.accounts.length) {
        this.setDefaultAccount();
      }
    });
  }
  public accounts = observable.array<BankAccount>([], { deep: false });
  public activeAccount: BankAccount | null | undefined = null;
  public state = State.loading;
  private timer: number | null = null;
  private psd2Timer: number | null = null;
  psd2Statuses = observable.array<Psd2Connection>([], { deep: false });

  setDefaultAccount = () => {
    const { clientState } = this.rootStore.statesStore;
    const defaultAccountId =
      clientState[ClientStateKey.defaultAccount]?.field ||
      path('0.id', this.accounts);

    if (defaultAccountId && !this.activeAccount) {
      const foundedAcc = find(propEq('id', defaultAccountId), this.accounts);
      this.setActiveAccount(foundedAcc || path('0', this.accounts));
    }
  };

  restoreDefaultAccount = () => {
    const { clientState } = this.rootStore.statesStore;
    const defaultAccountId =
      clientState[ClientStateKey.defaultAccount]?.field ||
      path('0.id', this.accounts);
    if (defaultAccountId) {
      const foundedAcc = find(propEq('id', defaultAccountId), this.accounts);
      this.setActiveAccount(foundedAcc || path('0', this.accounts));
    }
  };

  pollAccounts = async () => {
    this.timer = window.setTimeout(() => {
      if (isDocumentVisible()) {
        this.fetchBankAccounts(true);
      }
      this.pollAccounts();
    }, pollDelay);
  };

  pollPsd2 = async () => {
    this.psd2Timer = window.setTimeout(() => {
      if (isDocumentVisible()) {
        this.fetchPsd2Statuses();
      }
      this.pollPsd2();
    }, pollDelay);
  };

  fetchPsd2Statuses = flow(function* (this: AccountsStore) {
    const data: Psd2Connection[] = yield psd2Api.fetchStatus();
    this.psd2Statuses.replace(data);
  }).bind(this);

  public fetchBankAccounts = flow(function* (
    this: AccountsStore,
    silent = false
  ) {
    if (!silent) {
      this.state = State.loading;
    }
    const { affectedAccounts } = this.rootStore.userStore;
    try {
      const res = yield accountsApi.getBankAccounts();
      const mappedData = res.data.data.map((account: BankAccount) => ({
        ...account,
        affected:
          find(propEq('accountId', account.id), affectedAccounts) || null,
        isOutdated: account.lastSyncAt
          ? differenceInHours(parseISO(account.lastSyncAt), new Date()) > 24
          : true,
      }));
      this.accounts.replace(mappedData);
      if (this.activeAccount) {
        this.setActiveAccount(
          find(propEq('id', this.activeAccount.id), this.accounts)
        );
      }
      return res;
    } finally {
      this.state = State.loaded;
    }
  }).bind(this);

  public setActiveAccount = (account?: BankAccount) => {
    this.activeAccount = account;
  };

  get totalBalance() {
    return compose(
      sum,
      map<BankAccount, number>(prop('lastKnownBalance'))
    )(this.accounts);
  }

  get hasAccounts() {
    return Boolean(this.accounts.length);
  }

  public get isLoaded() {
    return isLoaded(this.state);
  }
  public get isLoading() {
    return isLoading(this.state);
  }
  public get isPending() {
    return isPending(this.state);
  }
  get affectedAccounts() {
    return filter(({ affected }) => Boolean(affected), this.accounts);
  }
  get activeConnections() {
    return filter(propEq('status', 'active'), this.psd2Statuses);
  }
  get awaitingConnections() {
    return filter(propEq('status', 'import_in_progress'), this.psd2Statuses);
  }
  get problemConnections() {
    return filter(propEq('status', 'access_problems'), this.psd2Statuses);
  }
  get authConnections() {
    return filter(propEq('status', 'auth_in_progress'), this.psd2Statuses);
  }
  get outdatedAccounts() {
    return filter(prop('isOutdated'), this.accounts);
  }
}

export default AccountsStore;
