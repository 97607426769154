import { store } from 'store/init';
import invariant from 'tiny-invariant';

const routes = {
  get basePath() {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return `/${activeEntity.relatedGemmsEntityType}/${activeEntity.relatedGemmsEntityId}/accounts`;
  },
  accountPath(accountId: string) {
    return [this.basePath, accountId].join('/');
  },
  disconnectAvailablePath(accountId: string) {
    return [this.basePath, accountId, 'disconnection_available'].join('/');
  },
};

export default routes;
