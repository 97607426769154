import api from 'api';
import { AxiosPromise } from 'axios';
import { find, propEq } from 'rambda';
import { useQuery } from 'react-query';
import { BankAccount, SepaTransfer } from 'apps/accounts/types';
import { useStore } from 'store';
import routes from './routes';

const accountsApi = {
  getBankAccounts(): AxiosPromise<{ data: BankAccount[] }> {
    return api(routes.basePath);
  },
  transferMoney(
    accountId: string,
    data: SepaTransfer
  ): AxiosPromise<{ url: string }> {
    return api.post(`/accounts/${accountId}/psd2/sepa`, data);
  },
  async fetchAccount(accountId: string) {
    const { data } = await api.get(`/accounts/${accountId}`);
    return data;
  },
  async fetchAccountTransactions({
    accountId,
    params,
  }: {
    accountId?: string;
    params: Record<string, string>;
  }) {
    const { data } = await api.get(`/accounts/${accountId}/dashboard`, {
      params,
    });
    return data.data;
  },
  async fetchAllTransactions({
    entityId,
    params,
  }: {
    entityId?: string;
    params: Record<string, string>;
  }) {
    const { data } = await api.get(`/accounts/${entityId}/dashboard/all`, {
      params,
    });
    return data.data;
  },
  async renameAccount(accountId: string, body: { bank: string }) {
    const { data } = await api.put(routes.accountPath(accountId), body);
    return data;
  },
  async manuallyUpdate(accountId: string) {
    const { data } = await api.post(`/accounts/${accountId}/update`);
    return data;
  },
  async checkDisconnectionAvailable(accountId: string) {
    const { data } = await api.get(routes.disconnectAvailablePath(accountId));
    return data;
  },
  disconnectAccount(accountId: string) {
    return api.delete(routes.accountPath(accountId));
  },
};

export const useAccount = (accountId: string) => {
  const { affectedAccounts } = useStore('userStore');
  return useQuery<BankAccount>(
    ['account', accountId],
    () => accountsApi.fetchAccount(accountId),
    {
      select: (account) => ({
        ...account,
        affected:
          find(propEq('accountId', accountId), affectedAccounts) || null,
      }),
      enabled: Boolean(accountId),
    }
  );
};

export const useAccountTransactions = ({
  accountId,
  params,
}: {
  accountId?: string;
  params: Record<string, string>;
}) => {
  return useQuery(
    ['dashboard', accountId, params],
    () =>
      accountsApi.fetchAccountTransactions({
        accountId,
        params,
      }),
    {
      enabled: Boolean(accountId),
      keepPreviousData: true,
    }
  );
};

export const useAllTransactions = ({
  entityId,
  params,
  enabled,
}: {
  entityId?: string;
  params: Record<string, string>;
  enabled: boolean;
}) => {
  return useQuery(
    ['dashboard', entityId, params],
    () =>
      accountsApi.fetchAllTransactions({
        entityId,
        params,
      }),
    {
      enabled,
      keepPreviousData: true,
    }
  );
};

export default accountsApi;
