import { makeObservable, action, computed, observable, flow } from 'mobx';
import history from 'routes/history';
import { State, AUTH_TOKEN } from 'const';
import { equals } from 'rambda';
import { ROUTE_NAMES } from 'routes/routeNames';
import { RootStore } from 'store';

class Bus {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      state: observable,
      setState: action,
      isLoaded: computed,
      isAuth: observable,
      setAuth: action,
    });
  }

  state: State = State.pending;
  isAuth = false;
  public setAuth = (status: boolean) => {
    this.isAuth = status;
  };
  init = flow(function* (this: Bus) {
    try {
      const isAuth = localStorage.getItem(AUTH_TOKEN);
      if (isAuth) {
        try {
          yield this.rootStore.userStore.getEntities();
          yield this.rootStore.userStore.fetchUser();
          yield this.rootStore.accountsStore.fetchBankAccounts();
          this.setAuth(true);
        } catch (e) {
          history.replace(ROUTE_NAMES.login);
          localStorage.removeItem(AUTH_TOKEN);
        }
      }
    } catch (e) {
      history.replace(ROUTE_NAMES.login);
      throw e;
    } finally {
      this.setState(State.loaded);
    }
  }).bind(this);

  public logout = () => {
    this.setState(State.pending);
    localStorage.removeItem(AUTH_TOKEN);
    window.location.href = ROUTE_NAMES.login;
  };

  setState(state: State) {
    this.state = state;
  }

  public get isLoaded() {
    return equals(this.state, State.loaded);
  }
}

export default Bus;
