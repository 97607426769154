import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 24px;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
`;
