export const ROUTE_NAMES = {
  root: '/',
  dashboard: '/dashboard',
  accounts: '/accounts/*',
  bills: '/bills',
  incoming: '/incoming/*',
  counterparts: '/counterparts/*',
  completeInvoice: '/invoices/:id/complete',
  transactions: '/transactions/*',
  psdError: '/psd2-error',
  psdSuccess: '/psd2-success',
  login: '/login',
  signup: '/signup',
  signupB: '/signup-b',
  taxSignup: '/tax-signup',
  invite: '/invite',
  expenses: '/expenses',
  allExpenses: '/expenses/all/*',
  reports: '/expenses/reports/*',
  settings: '/settings',
  profile: '/profile',
  profileSettings: '/settings/profile',
  companySettings: '/settings/company',
  taxSettings: '/settings/tax',
  invoices: '/invoices/*',
  products: '/products',
  accounting: '/accounting/*',
  team: '/team/*',
  permissions: '/permissions/*',
  accountant: '/accountant/*',
  acceptInvite: '/accept-invite',
};
