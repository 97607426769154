import { PopupPosition } from './DropMenu';
import styled from 'styled-components';
import { Colors } from '../styles/theme';

export const ToggleWrapper = styled.div`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
`;

const Position: Record<string, Record<string, string | number>> = {
  left: {
    right: 35,
    top: 0,
  },
  center: {
    left: 0,
    top: 0,
  },
  bottomRight: {
    right: 0,
    marginTop: 8,
  },
  topLeft: {
    bottom: 'calc(100% + 15px)',
    left: 0,
  },
  topRight: {
    bottom: 'calc(100% + 15px)',
    right: 0,
  },
};

const AnimationPosition: Record<
  PopupPosition,
  Record<string, string | number>
> = {
  left: {
    transform: `translateX(15px)`,
  },
  right: {
    transform: `translateX(-15px)`,
  },
  top: {
    transform: `translateY(15px)`,
  },
  bottom: {
    transform: `translateY(-15px)`,
  },
  center: {
    transform: `translateY(-15px)`,
  },
  bottomRight: {
    transform: `translateY(-15px)`,
  },
  topLeft: {
    transform: `translateY(15px)`,
  },
  topRight: {
    transform: `translateY(15px)`,
  },
};

export const SPopup = styled.div<{
  position: PopupPosition;
  width?: number;
}>`
  position: absolute;
  z-index: 101;
  overflow: hidden;
  background: ${Colors.white};
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 1px rgba(15, 15, 15, 0.05);
  border-radius: 12px;
  width: ${({ width = 200 }) => width}px;
  ${({ position }) => Position[position]};
  button:not(.react-datepicker__close-icon):not(.no-hover) {
    transition: background-color 0.15s ease-in-out;
    width: 100%;
    &:hover {
      background-color: ${Colors.gray7};
    }
  }
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: 0.3s;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: 0.3s;
  }
  &.slide-enter {
    opacity: 0;
    ${({ position }) => AnimationPosition[position]};
  }
  &.slide-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: 0.2s;
  }
  &.slide-exit {
    opacity: 1;
    transform: translate(0, 0);
  }
  &.slide-exit-active {
    opacity: 0;
    ${({ position }) => AnimationPosition[position]};
    transition: 0.2s;
  }
`;
