import entityApi from 'api/entities';
import { makeObservable, action, computed, observable, when, flow } from 'mobx';
import { AxiosResponse } from 'axios';
import { DEFAULT_ENTITY_KEY, State } from 'const';
import { find, propEq } from 'rambda';
import { EntityData } from 'types/entities';
import { RootStore } from './index';

export class EntityStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.busStore.isAuth,
      () => {
        this.fetchEntities();
      }
    );
    makeObservable(this, {
      state: observable,
      activeEntity: observable.ref,
      setState: action,
      fullAddress: computed,
    });
  }
  public entities = observable.array<EntityData>([], { deep: false });
  public state: State = State.loading;
  public activeEntity: EntityData | null = null;
  public setState = (state: State) => {
    this.state = state;
  };

  public fetchEntities = flow(function* (this: EntityStore) {
    this.setState(State.loading);
    try {
      const res = yield entityApi.getEntities();
      this.entities.replace(res.data.data);
      const activeEntity = this.getActiveEntity();
      if (activeEntity) {
        this.setActiveEntity(activeEntity);
      }
      this.setState(State.loaded);
      return res;
    } catch (e) {
      this.setState(State.error);
      throw e;
    }
  });

  setActiveEntity = (entity: EntityData) => {
    this.activeEntity = entity;
  };

  public createEntity = flow(function* (
    this: EntityStore,
    data: Partial<EntityData>
  ) {
    this.setState(State.saving);
    try {
      const res: AxiosResponse<EntityData> = yield entityApi.createEntity(data);
      this.entities.push(res.data);
      this.activeEntity = res.data;
      this.setState(State.loaded);
      localStorage.setItem(DEFAULT_ENTITY_KEY, this.activeEntity.id);
      return res;
    } catch (e) {
      this.setState(State.error);
      throw e;
    }
  });

  public updateEntity = flow(function* (
    this: EntityStore,
    data: Partial<EntityData>
  ) {
    const { activeEntity } = this.rootStore.userStore;
    if (!this.activeEntity) return;
    const res = yield entityApi.updateEntity(this.activeEntity.id, data);
    this.activeEntity = res.data;
    if (activeEntity) {
      activeEntity.entityDetails.entityName = res.data.companyName;
      activeEntity.entityName = res.data.companyName;
    }
  }).bind(this);

  public uploadAvatar = flow(function* (this: EntityStore, data: FormData) {
    if (!this.activeEntity) return;
    const res = yield entityApi.uploadAvatar(this.activeEntity.id, data);
    this.activeEntity = res.data;
  }).bind(this);

  get fullAddress() {
    if (!this.activeEntity) return '';
    const { addressCity, addressState, addressLine1, addressPostalCode } =
      this.activeEntity;
    return [addressLine1, addressState, addressCity, addressPostalCode]
      .filter(Boolean)
      .join(', ');
  }
  getActiveEntity = () => {
    const defaultEntityId = localStorage.getItem(DEFAULT_ENTITY_KEY);
    if (defaultEntityId) {
      const defaultEntity = find(propEq('id', defaultEntityId), this.entities);
      if (defaultEntity) return defaultEntity;
      return this.entities[0];
    }
    return this.entities[0];
  };
}

export default EntityStore;
