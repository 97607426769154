import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { updateAbility } from 'ability';
import userApi from 'api/user';
import { BankAccount } from 'apps/accounts/types';
import { AxiosResponse } from 'axios';
import { DEFAULT_ENTITY_KEY } from 'const';
import { UserStatus } from 'const/user';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import {
  compose,
  equals,
  filter,
  find,
  forEach,
  includes,
  merge,
  propEq,
  propOr,
  reduce,
} from 'rambda';
import { RootStore } from 'store';
import {
  PersonalData,
  Takeoff,
  User,
  UserAddress,
  UserEntity,
  UserPic,
} from 'types/user';
import { isProduction, PRODUCTION_ENV, REACT_APP_SENTRY_DSN } from 'utils/env';
import { version } from '../../package.json';

class UserStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isReady: observable,
      isAuth: observable,
      user: observable.ref,
      takeoff: observable.ref,
      activeEntity: observable.ref,
      userpic: observable.ref,
      isDevMode: observable,
      setReady: action,
      authUser: action,
      fullName: computed,
      fullAddress: computed,
      userWithoutPassword: computed,
      invoicesEmail: computed,
      receiptsEmail: computed,
      isEmptyCompanyAddress: computed,
      isRequiredSignature: computed,
      setActiveEntity: action,
      setTakeoff: action,
      setAuth: action,
    });
  }
  isReady = false;
  takeoff: Takeoff | null = null;
  user: User | null = null;
  activeEntity: UserEntity | null | undefined = null;
  entities: UserEntity[] = observable.array([], { deep: false });
  userpic: UserPic | null = null;
  isDevMode = false;
  isAuth = false;
  setReady = () => {
    this.isReady = true;
  };

  setAuth = (val: boolean) => (this.isAuth = val);

  private setupAnalytics = () => {
    if (!this.user || !isProduction || !PRODUCTION_ENV) return;
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      release: version,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
    LogRocket.init(process.env.REACT_APP_LOGROCKET as string, {
      dom: {
        textSanitizer: true,
        inputSanitizer: true,
      },
    });
    LogRocket.identify(this.user.id, {
      name: this.fullName,
      email: this.user.email,
      companyId: this.activeEntity?.relatedGemmsEntityId || '',
    });
    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
    setupLogRocketReact(LogRocket);
  };

  authUser(user: User) {
    this.user = user;
    this.setAuth(true);
    if (!this.userWithoutPassword) {
      this.setReady();
    }
    this.setupAnalytics();
  }

  public fetchUser = flow(function* (this: UserStore) {
    const res: AxiosResponse<User> = yield userApi.getUser();
    this.authUser(res.data);
    return res;
  }).bind(this);

  public updatePersonalData = flow(function* (
    this: UserStore,
    data: Partial<PersonalData>
  ) {
    const res: AxiosResponse<PersonalData> = yield userApi.updatePersonalData(
      data
    );
    if (this.user) {
      this.user = merge(this.user, res.data);
    }
    return res;
  }).bind(this);

  updateAddress = flow(function* (this: UserStore, body: UserAddress) {
    const data = yield userApi.updateAddress(body);
    if (this.user) {
      this.user = merge(this.user, data);
    }
  }).bind(this);

  setActiveEntity = (entity: UserEntity) => {
    this.activeEntity = entity;
    updateAbility(entity);
  };

  setTakeoff = (takeoff: Takeoff) => {
    this.takeoff = takeoff;
  };

  public setPassword = flow(function* (password: string) {
    yield userApi.setPassword(password);
  });

  public uploadAvatar = flow(function* (this: UserStore, data: FormData) {
    const res = yield userApi.uploadAvatar(data);
    this.userpic = res.data;
    return res;
  }).bind(this);

  getActiveEntity = () => {
    const defaultEntityId = localStorage.getItem(DEFAULT_ENTITY_KEY);
    if (defaultEntityId) {
      const defaultEntity = find(
        propEq('relatedGemmsEntityId', defaultEntityId),
        this.entities
      );
      if (defaultEntity) return defaultEntity;
      localStorage.removeItem(DEFAULT_ENTITY_KEY);
      return this.entities[0];
    }
    localStorage.setItem(
      DEFAULT_ENTITY_KEY,
      this.entities[0].relatedGemmsEntityId
    );
    return this.entities[0];
  };

  public getEntities = flow(function* (this: UserStore) {
    const { data }: { data: Takeoff } = yield userApi.getEntities();
    this.setTakeoff(data);
    this.entities = data.entities;
    const activeEntity = this.getActiveEntity();
    if (activeEntity) {
      this.setActiveEntity(activeEntity);
    }
    this.isDevMode = data.devMode;
    this.userpic = data.userpic;
    return data;
  }).bind(this);

  public get fullName() {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }

  public get fullAddress() {
    if (!this.user?.address) return '';
    const { addressLine1, addressLine2, city, postalCode, state, country } =
      this.user.address;
    if (!addressLine1) return 'No address';
    return [addressLine1, addressLine2, postalCode, city, state, country].join(
      ', '
    );
  }

  get userWithoutPassword() {
    return equals(this.user?.userStatus, UserStatus.CREATE_WITHOUT_PASSWORD);
  }

  get invoicesEmail() {
    return this.activeEntity?.emails?.invoicesEmail;
  }

  get receiptsEmail() {
    return this.activeEntity?.emails?.receiptsEmail;
  }
  get galleryEmail() {
    return this.activeEntity?.emails?.galleryEmail;
  }
  get isEmptyCompanyAddress() {
    if (!this.activeEntity) return false;
    return includes(
      'company_address_required',
      this.activeEntity.interfaceBehaviourFlags
    );
  }
  get isRequiredSignature() {
    if (!this.activeEntity || !this.takeoff) return true;
    return includes(
      'personal_signature_required',
      this.takeoff?.userBehaviourFlags
    );
  }
  get isRequiredUserAddress() {
    if (!this.activeEntity || !this.takeoff) return true;
    return includes(
      'personal_address_required',
      this.takeoff.userBehaviourFlags
    );
  }
  get isRequiredBankAccount() {
    if (!this.activeEntity || !this.takeoff) return true;
    return includes(
      'cant_create_expense_reports_without_having_personal_bank_account',
      this.takeoff.userBehaviourFlags
    );
  }
  get isRequiredVatTax() {
    if (!this.activeEntity) return false;
    return includes(
      'company_has_not_tax_vat_ids',
      this.activeEntity.interfaceBehaviourFlags
    );
  }
  get isRequiredSignedTerms() {
    if (!this.takeoff) return false;
    return includes(
      'last_version_of_t_and_c_not_signed',
      this.takeoff.userBehaviourFlags
    );
  }
  get isRequiredPasswordChange() {
    if (!this.takeoff) return false;
    return includes(
      'password_should_be_changed',
      this.takeoff.userBehaviourFlags
    );
  }
  get address() {
    return this.user?.address;
  }
  get isFreelancer() {
    return this.activeEntity?.relatedGemmsEntityType === 'person';
  }
  get affectedAccounts() {
    if (!this.activeEntity) return [];
    return reduce(
      (acc: { accountId: string; name: string }[], el) => {
        if (!el.affectedBankAccounts) return acc;
        forEach((id) => {
          acc.push({
            accountId: id,
            name: compose(
              propOr('', 'bank'),
              find<BankAccount>(propEq('id', id))
            )(this.rootStore.accountsStore.accounts),
          });
        }, el.affectedBankAccounts);
        return acc;
      },
      [],
      this.activeEntity.psd2AuthRequests
    );
  }
  get invalidCredentialsAccounts() {
    if (!this.activeEntity) return [];
    return filter(
      ({ affectedBankAccounts }) => !Boolean(affectedBankAccounts),
      this.activeEntity.psd2AuthRequests
    );
  }

  get isTwoFaActive() {
    if (!this.activeEntity || !this.takeoff) return false;
    return includes('2fa_is_enabled', this.takeoff.userBehaviourFlags);
  }
}

export default UserStore;
