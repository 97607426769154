import { useState, useCallback, SyntheticEvent } from 'react';

export default function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const show = useCallback(() => setValue(true), []);
  const hide = useCallback(() => setValue(false), []);
  const set = useCallback((value: boolean) => setValue(value), []);
  const toggle = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setValue((state) => !state);
  }, []);

  return { value, isOpen: value, show, hide, set, toggle };
}
