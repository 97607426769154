import init from 'init';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'fonts/fonts.css';
import 'styles/sanitize.css';
import 'styles/common.css';
import 'styles/transitions.css';
import 'styles/toast.css';

(async function () {
  const vdom = await init();
  ReactDOM.render(vdom, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
