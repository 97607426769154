import React from 'react';
import { UilCircle, UilCheckCircle } from '@iconscout/react-unicons';
import { Colors } from '../styles/theme';

const Checkbox = ({ checked }: { checked: boolean }) => {
  return checked ? (
    <UilCheckCircle color={Colors.textBlack} />
  ) : (
    <UilCircle color="#EBEBEB" />
  );
};

export default Checkbox;
