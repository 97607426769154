import styled, { DefaultTheme } from 'styled-components';
import { ReactNode } from 'react';

export interface CellProps {
  className?: string;
  $width: number | Record<string, number>;
  height?: number;
  top?: number | string;
  left?: number | string;
  middle?: boolean;
  center?: boolean;
  centerH?: boolean;
  area?: string;
  children: ReactNode;
  align?: 'left' | 'center' | 'right';
}

const getWidth = ({
  $width,
}: {
  $width: number | Record<string, number>;
  theme: DefaultTheme;
}) => {
  if (typeof $width === 'number') {
    return `grid-column-end: span ${$width}`;
  }
  return Object.keys($width).reduce((acc, key) => {
    const point = key.substring(1);
    return `${acc}; @media (min-width: ${point}px) { grid-column-end: span ${$width[key]} }`;
  }, '');
};

const Cell = styled.div<CellProps>`
  height: 100%;
  min-width: 0;
  ${getWidth};
  grid-row-end: ${({ height = 1 }: CellProps) => `span ${height}`};
  ${/* prettier-ignore */
  ({ middle }: CellProps) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `};
  ${({ centerH }) => centerH && 'align-items: center'};
  ${({ left }: CellProps) => left && `grid-column-start: ${left}`};
  ${({ top }: CellProps) => top && `grid-row-start: ${top}`};
  ${({ center }: CellProps) => center && `text-align: center`};
  ${({ area }: CellProps) => area && `grid-area: ${area}`};
  ${({ align }: CellProps) => align && `text-align: ${align}`};
`;

export default Cell;
