import { ListLoadLimit } from 'const';
import { getNewerDate } from 'helpers/filterHelpers';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { equals } from 'rambda';
import { formatISO } from 'date-fns';

type FilterVal = any;

const defaultFilters: Record<string, FilterVal> = {
  status: '',
  type: '',
  date: '',
  newerThan: '',
  olderThan: '',
  limit: ListLoadLimit,
};

class FiltersStore {
  constructor() {
    makeObservable(this, {
      filters: observable,
      setFilter: action,
      setFilters: action,
      clearFilters: action,
      filtersQuery: computed,
      setDirty: action,
      dirty: observable,
    });
  }

  public filters: Record<string, Record<string, any>> = {
    invoices: { ...defaultFilters },
    transactions: { ...defaultFilters },
    reports: { ...defaultFilters },
    incomingBills: { ...defaultFilters },
    expenses: { ...defaultFilters },
    invoiceTransactions: {
      ...defaultFilters,
      type: 'incoming',
      withoutAttachedDocuments: true,
    },
    accounting: { ...defaultFilters, name: 'All time' },
    products: { ...defaultFilters },
    accountant: { ...defaultFilters },
  };
  public setFilter = (entity: string, name: string, val: FilterVal) => {
    this.filters[entity][name] = val;
  };
  public setFilters = (entity: string, filters: Record<string, FilterVal>) => {
    this.filters[entity] = observable.object(filters);
  };
  public setDirty = (entity: string, isDirty: boolean) =>
    (this.dirty[entity] = isDirty);
  public clearFilters = (entity: string) => {
    this.filters[entity] = observable.object(defaultFilters);
    this.dirty[entity] = false;
  };
  dirty = {
    invoices: false,
    transactions: false,
    reports: false,
    incomingBills: false,
    expenses: false,
    invoiceTransactions: false,
    accounting: false,
  };
  public get filtersQuery() {
    return Object.entries(toJS(this.filters)).reduce(
      (acc, [entity, filters]) => {
        if (entity === 'accounting') {
          acc[entity] = {
            limit: ListLoadLimit,
            filters: filters.filters,
          };
          return acc;
        }

        const {
          date,
          status,
          newerThan: customNewerThan,
          olderThan,
          type,
          withoutAttachedDocuments,
          offset,
        } = filters;
        const newerThan = getNewerDate(date as string);
        const isCustom = equals(date, 'custom');
        acc[entity] = {
          status,
          type,
          withoutAttachedDocuments,
          limit: ListLoadLimit,
          newer_than: isCustom
            ? formatISO(customNewerThan as Date, { representation: 'date' })
            : newerThan && formatISO(newerThan, { representation: 'date' }),
          older_than:
            olderThan &&
            formatISO(olderThan as Date, { representation: 'date' }),
          offset,
        };
        return acc;
      },
      {}
    );
  }
}

export default FiltersStore;
