import { observable, autorun, makeObservable, action } from 'mobx';
import { find, propEq } from 'rambda';
import { QueryKey } from 'react-query';
import { RootStore } from 'store';
import { AccountingItem } from './types';

class AccountingStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    autorun(() => {
      this.queryKey.replace([
        this.entityType,
        this.rootStore.filtersStore.filtersQuery[this.entityType],
      ]);
    });
    makeObservable(this, {
      setIncomplete: action,
      selectIncompleteRecord: action,
      clearIncomplete: action,
      selectedIncompleteRecord: observable,
    });
  }
  entityType = 'accounting';
  selectedIncompleteRecord = '';
  queryKey = observable.array<QueryKey>([]);
  incomplete = observable.array<AccountingItem>([], { deep: false });
  setIncomplete = (incomplete: AccountingItem[]) => {
    this.incomplete.replace(incomplete);
  };
  selectIncompleteRecord = (id: string) => (this.selectedIncompleteRecord = id);

  clearIncomplete = () => this.incomplete.clear();

  removeIncomplete = (relatedGemmsObjectId: string) => {
    const rec = find(
      propEq('relatedGemmsObjectId', relatedGemmsObjectId),
      this.incomplete
    );
    if (!rec) return;
    this.incomplete.remove(rec);
  };
}

export default AccountingStore;
