import React, { ReactNode, useMemo } from 'react';
import SimpleBar from 'simplebar-react';
import ModalFooter from './ModalFooter';
import ModalTop from './ModalTop';
import { Dialog } from './styles';
import { View } from '../View';
import ModalContainer from './ModalContainer';

const maxModalHeight = 728;
const bodyHeight = 584;

interface Props {
  renderTop?: (isVisible: boolean) => ReactNode;
  renderFooter?: () => ReactNode;
  children: ReactNode;
  onClose?: () => void;
  lockScroll?: boolean;
  width?: string;
  title?: string;
  height?: string;
}

const Modal = ({
  lockScroll,
  onClose,
  renderFooter,
  children,
  width,
  title,
  height,
}: Props) => {
  const innerHeight =
    height || window.innerHeight > maxModalHeight
      ? bodyHeight
      : window.innerHeight - 210;
  const bodyStyle = useMemo(() => ({ height: innerHeight }), [innerHeight]);
  return (
    <ModalContainer width={width} onClose={onClose} lockScroll={lockScroll}>
      <Dialog>
        <ModalTop title={title} onClose={onClose} />
        <SimpleBar forceVisible="y" autoHide={false} style={bodyStyle}>
          <View paddingH={24}>{children}</View>
        </SimpleBar>
        {renderFooter && renderFooter()}
      </Dialog>
    </ModalContainer>
  );
};

Modal.Footer = ModalFooter;
Modal.Header = ModalTop;

export default Modal;
