import React from 'react';
import {
  SliderInput,
  SliderTrack,
  SliderTrackHighlight,
  SliderHandle,
  SliderProps,
} from '@reach/slider';
import '@reach/slider/styles.css';
import styled from 'styled-components';
import { Colors } from '../styles/theme';

const SliderWrapper = styled.div`
  [data-reach-slider-input] {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  [data-reach-slider-track-highlight] {
    background: ${Colors.textBlack};
  }
  [data-reach-slider-handle] {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.16),
      0 3px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.04);
  }
  [data-reach-slider-track] {
    background: #c7c7cc;
  }
  [data-reach-slider-input][data-orientation='horizontal'] {
    height: 2px;
  }
`;

export const Range = (props: SliderProps) => {
  return (
    <SliderWrapper>
      <SliderInput {...props}>
        <SliderTrack>
          <SliderTrackHighlight />
          <SliderHandle>{props.children}</SliderHandle>
        </SliderTrack>
      </SliderInput>
    </SliderWrapper>
  );
};

export default Range;
