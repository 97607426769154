import api from 'api/index';
import { AxiosPromise } from 'axios';
import { States } from 'types/states';

/*
  Store client state.
 */
class StatesApi {
  /*
    Read users states.
    It helps to manage UX elements and tells everything about
    current users situation in terms of biz logic
   */
  getState(): AxiosPromise<States> {
    return api('/states');
  }
  /*
    Store client state.
   */
  saveState(stateId: string, state: Record<string, any>): AxiosPromise<States> {
    return api.post('/states', { stateId, state });
  }

  deleteState(stateId: string): AxiosPromise<States> {
    return api.delete(`/states/${stateId}`);
  }
}

const statesApi = new StatesApi();

export default statesApi;
