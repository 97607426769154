import api from 'api';
import {
  DiemExpense,
  DiemReference,
  Expense,
  MileageExpense,
  MileageReference,
  LostReceiptExpenseDto,
  RegularReceiptExpenseDto,
  ReceiptExpense,
  ExpenseModel,
  ExpenseDocument,
  MileageExpense as MileageExpenseInterface,
} from 'apps/expenses/types';
import { AxiosRequestConfig } from 'axios';
import { decamelizeKeys } from 'humps';
import { QueryFunctionContext, useQuery } from 'react-query';
import routes from './routes';

const expensesApi = {
  async fetchData({ pageParam, queryKey }: QueryFunctionContext) {
    const [, params] = queryKey;
    const { data } = await api.get<{ data: Expense<ExpenseModel>[] }>(
      routes.basePath,
      {
        params: decamelizeKeys({
          ...(params as Record<string, unknown>),
          olderThan: pageParam,
        }),
      }
    );
    return data.data;
  },
  async fetchMileageReference() {
    const { data } = await api.get<{ data: MileageReference[] }>(
      routes.mileageReferencePath()
    );
    return data.data;
  },
  async fetchDiemReference() {
    const { data } = await api.get<{ data: DiemReference[] }>(
      routes.diemReferencePath()
    );
    return data.data;
  },

  async createDiemExpense({ body }: { id?: string; body: DiemExpense }) {
    const { data } = await api.post<Expense<DiemExpense>>(
      routes.diemExpensesPath(),
      body
    );
    return data;
  },
  async updateDiemExpense({ id, body }: { id: string; body: DiemExpense }) {
    const { data } = await api.put<Expense<DiemExpense>>(
      routes.diemExpensePath(id),
      body
    );
    return data;
  },
  async createMileageExpense({
    body,
  }: {
    id?: string;
    body: MileageExpenseInterface;
  }) {
    const { data } = await api.post<Expense<MileageExpense>>(
      routes.mileageExpensesPath(),
      body
    );
    return data;
  },
  async updateMileageExpense({
    id,
    body,
  }: {
    id: string;
    body: MileageExpenseInterface;
  }) {
    const { data } = await api.put<Expense<MileageExpense>>(
      routes.mileageExpensePath(id),
      body
    );
    return data;
  },
  async createLostExpense({
    body,
  }: {
    id?: string;
    body: LostReceiptExpenseDto;
  }) {
    const { data } = await api.post<Expense<ReceiptExpense>>(
      routes.lostExpensesPath(),
      body
    );
    return data;
  },
  async updateLostExpense({
    id,
    body,
  }: {
    id: string;
    body: LostReceiptExpenseDto;
  }) {
    const { data } = await api.put<Expense<ReceiptExpense>>(
      routes.lostExpensePath(id),
      body
    );
    return data;
  },
  async updateRegularExpense({
    id,
    body,
  }: {
    id: string;
    body: RegularReceiptExpenseDto;
  }) {
    const { data } = await api.put<Expense<ReceiptExpense>>(
      routes.regularExpensePath(id),
      body
    );
    return data;
  },
  async recognizeExpenseFile({
    body,
    config,
  }: {
    body: FormData;
    config?: AxiosRequestConfig;
  }) {
    const { data } = await api.post(routes.regularExpensesPath(), body, config);
    return data;
  },
  async deleteExpense(expenseId: string) {
    const { data } = await api.delete<void>(routes.expensePath(expenseId));
    return data;
  },
  async fetchDocuments(expenseId: string) {
    const { data } = await api.get<ExpenseDocument>(
      routes.documentsPath(expenseId)
    );
    return data;
  },
  async replaceFile({
    expenseId,
    body,
  }: {
    expenseId: string;
    body: FormData;
  }) {
    const { data } = await api.post(routes.replaceFilePath(expenseId), body);
    return data;
  },
};

export const useFile = (expenseId: string) =>
  useQuery(
    ['expenseDocument', expenseId],
    () => expensesApi.fetchDocuments(expenseId),
    {
      enabled: Boolean(expenseId),
    }
  );

export default expensesApi;
