import { Locale } from 'types/common';
import { Currency, CurrencySign } from 'types/currency';

export const CURRENCY_SIGN: Record<Currency, CurrencySign> = {
  EUR: '€',
  USD: '$',
};

export enum State {
  pending = 'pending',
  loaded = 'loaded',
  loading = 'loading',
  error = 'error',
  saving = 'saving',
}

export const LANGUAGE_KEY = 'i18nextLng';
export const AUTH_TOKEN = 'authToken';
export const DEFAULT_ENTITY_KEY = 'defaultEntity';

export const column: Record<string, number> = {
  b320: 6,
  b360: 7,
  b412: 8,
  b600: 12,
  b768: 15,
  b968: 19,
  b1024: 17,
  b1280: 24,
  b1366: 24,
  b1440: 24,
  b1680: 24,
  b1920: 24,
};

export const columnsGap = '20px';
export enum PORTAL {
  MODAl = 'modal',
}

export const ListLoadLimit = 25;

export const ClientStateKey: Record<string, string> = {
  signature: 'signature',
  defaultAccount: 'defaultAccount',
};

export enum Language {
  DE = 'DE',
  EN = 'EN',
}

export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
export const APPROVE_REQUIRED_CODE = 451;

export const AVOID_NAME_CHARACTERS =
  /^([^:$^.~<>"|;?№§[\],_/\\%!#&*(){}@+\-=\d]*)$/;
export const AVOID_INVOICE_NO = /^[a-zA-Z0-9\s/-?:().,'+]+$/;
export const AVOID_PAYMENT_REFERENCE = /^([^$^~<>"|;№§%!#&*{}@=\\[\]`_]*)$/;
export const AVOID_COUNTERPART_NAME = /^([^$^~<>"|;№§%!#&*{}@=\\[\]`_]*)$/;

export const INTERCOM_APP_ID = 'ppmvqub2';

export const IBAN_MASK = 'aa** **** **** **** **** **** **** ****';

export const TOUR_ID: Record<Locale, Record<string, number>> = {
  en: {
    transactions: 212005,
    team: 212049,
    counterparts: 212040,
    products: 212034,
    accounts: 212031,
    accounting: 212029,
    reports: 212020,
    expenses: 212014,
    dashboard: 211923,
    dashboardNew: 212400,
    invoices: 212009,
    incoming: 212758,
  },
  de: {
    transactions: 213659,
    team: 213691,
    counterparts: 213689,
    products: 213685,
    accounts: 213683,
    accounting: 213681,
    reports: 213669,
    expenses: 213668,
    dashboard: 213657,
    dashboardNew: 213656,
    invoices: 213666,
    incoming: 213664,
  },
};

export const emptyArr: any[] = [];

export const IDLE_TIMEOUT = 1000 * 60 * 9.5;
export const LOGOUT_TIMEOUT = 30;
export const MAX_ACCOUNT_NAME_LENGTH = 70;
export const MAX_COUNTERPART_NAME_LENGTH = 70;
export const IBAN_PLACEHOLDER = 'DE00 0000 0000 0000 0000 00';
export const CODE_EXPIRE_TIMEOUT = 90;
