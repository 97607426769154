import styled from 'styled-components';
import { ReactNode, Ref } from 'react';

export interface GridProps {
  className?: string;
  columns: string | number | Record<string, number>;
  gap: string;
  columnGap?: string;
  rowGap?: string;
  height?: string;
  minRowHeight?: string;
  flow?: string;
  rows?: string | number;
  areas?: string[];
  justifyContent?: string;
  alignContent?: string;
  alignItems?: string;
  cellWidth?: string;
  children: ReactNode;
  fullHeight?: boolean;
}

const frGetter = (value: number | string, cellWidth: string) =>
  typeof value === 'number' ? `repeat(${value}, ${cellWidth})` : value;
const autoRows = ({ minRowHeight = '20px' }: GridProps) =>
  `minmax(${minRowHeight}, auto)`;
const gap = ({ gap = '8px' }: GridProps) => gap;
const flow = ({ flow = 'row' }: GridProps) => flow;
const height = ({ height = 'auto' }: GridProps) => height;
const formatAreas = (areas: string[]) =>
  areas.map((area) => `"${area}"`).join(' ');
const columns = ({ columns = 12, cellWidth = '24px' }: GridProps) => {
  if (typeof columns === 'number') {
    return `grid-template-columns: ${frGetter(columns, cellWidth)}`;
  }
  if (typeof columns === 'string') {
    return `grid-template-columns: ${columns}`;
  }
  return Object.keys(columns).reduce((acc, key) => {
    const point = key.substring(1);
    return `${acc}; @media (min-width: ${point}px) { grid-template-columns: repeat(${columns[key]}, ${cellWidth}) }`;
  }, '');
};

const Grid = styled.div<GridProps & { ref?: Ref<unknown> }>`
  display: grid;
  height: ${height};
  grid-auto-flow: ${flow};
  grid-auto-rows: ${autoRows};
  grid-gap: ${gap};
  ${columns};
  ${({ fullHeight }) => fullHeight && 'min-height: 100vh'};
  ${({ rows, cellWidth = '24px' }) =>
    rows && `grid-template-rows: ${frGetter(rows, cellWidth)}`};
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap}`};
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};
  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
  ${({ justifyContent = 'space-between' }) =>
    `justify-content: ${justifyContent}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
`;

export default Grid;
