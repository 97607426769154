import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/theme';

interface RoundBtnProps {
  onClick: () => void;
  icon: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'xl';
  theme?: 'dark' | 'light';
  ariaLabel?: string;
}

const Wrapper = styled.button`
  border-radius: 12px;
  padding-top: 3px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: ${Colors.gray4};
`;

const RoundBtn = ({ onClick, icon, ariaLabel }: RoundBtnProps) => {
  const Icon = icon as any;
  return (
    <Wrapper onClick={onClick} type="button" aria-label={ariaLabel}>
      <Icon color={Colors.dark} />
    </Wrapper>
  );
};

export default RoundBtn;
