import styled from 'styled-components';
import { Colors } from '../styles/theme';

const RadioBtn = styled.div<{ selected: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  transition: border 0.15s ease-in-out;
  border: 2px solid
    ${({ selected }) => (selected ? Colors.monocolor100 : Colors.monocolor10)};
  &::after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${Colors.monocolor100};
    display: block;
    left: 50%;
    top: 50%;
    transition: transform 0.15s ease-in-out;
    transform: translate(-50%, -50%)
      scale(${({ selected }) => (selected ? 1 : 0)});
  }
`;

export default RadioBtn;
