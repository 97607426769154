/**
 * Generate color for category icon from name
 * @param str
 * @param s
 * @param l
 */
function stringToHslColor(str: string, s = 100, l = 80) {
  if (str.toLocaleLowerCase() === 'other') {
    return '#1b1c20';
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

export default stringToHslColor;
