import { formatDistanceToNow, parseISO } from 'date-fns';
import i18n, { dateLocales } from 'i18n';

const formatLastSync = (date: string | null) => {
  if (!date)
    return {
      lastSyncDate: '',
      humanRead: '',
    };
  const lastSyncDate = parseISO(date);
  const humanRead = formatDistanceToNow(lastSyncDate, {
    addSuffix: true,
    locale: dateLocales[i18n.language],
  });
  return { lastSyncDate, humanRead };
};

export default formatLastSync;
