import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Color, Colors, TColor } from '../styles/theme';
import { View, ViewProps } from '../View';

export type TypographySize =
  | '4xl'
  | '3xl'
  | '2xl'
  | 'xl'
  | 'regular'
  | 'sm'
  | 'xs'
  | 'xxs'
  | 'inherit'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'largeBody'
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'overline';

export type TypographyWeight = 'bold' | 'semibold' | 'medium' | 'normal';
export interface TypographyProps extends ViewProps {
  size?: TypographySize;
  weight?: TypographyWeight;
  align?: 'initial' | 'inherit' | 'left' | 'center' | 'right' | 'justify';
  children: ReactNode;
  className?: string;
  color?: Colors | string;
  wrap?: boolean;
}

const Font: {
  [key in TypographySize]: { [key in string]: string | number };
} = {
  '4xl': {
    'font-size': 48,
    'line-height': '54px',
  },
  '3xl': {
    'font-size': 28,
    'line-height': '1.28',
  },
  '2xl': {
    'font-size': 24,
    'line-height': '1.5',
  },
  xl: {
    'font-size': 18,
    'line-height': '24px',
  },
  regular: {
    'font-size': 14,
    'line-height': '24px',
  },
  sm: {
    'font-size': 14,
    'line-height': '1.3',
  },
  xs: {
    'font-size': 12,
    'line-height': '20px',
  },
  xxs: {
    'font-size': 11,
    'line-height': '1.5',
  },
  inherit: {
    'font-size': 'inherit',
    'line-height': 'inherit',
  },
  h1: {
    'font-size': 96,
    'line-height': '1.16',
    'letter-spacing': '-1.5px',
    'font-weight': '500',
  },
  h2: {
    'font-size': 60,
    'line-height': '1.2',
    'letter-spacing': '-0.5px',
  },
  h3: {
    'font-size': 48,
    'line-height': '1.2',
    'font-weight': '500',
  },
  h4: {
    'font-size': 34,
    'line-height': '1.05',
    'font-weight': '600',
  },
  h5: {
    'font-size': 24,
    'line-height': '1.5',
    'letter-spacing': '0.3px',
    'font-weight': '600',
  },
  h6: {
    'font-size': 20,
    'line-height': '1.2',
    'letter-spacing': '0.2px',
    'font-weight': '500',
  },
  subtitle1: {
    'font-size': 16,
    'line-height': '1.35',
    'letter-spacing': '0.4px',
  },
  subtitle2: {
    'font-size': 14,
    'line-height': '1.71',
    'font-weight': '500',
  },
  largeBody: {
    'font-size': 28,
    'line-height': '1.142',
    'font-weight': '500',
  },
  body1: {
    'font-size': 16,
    'line-height': '1.375',
    'letter-spacing': '0.4px',
  },
  body2: {
    'font-size': 14,
    'line-height': '1.428',
  },
  button: {
    'font-size': 16,
    'line-height': '1.5',
    'font-weight': '500',
    'letter-spacing': '0.4px',
  },
  caption: {
    'font-size': 12,
    'line-height': '1.333',
  },
  overline: {
    'font-size': 10,
    'line-height': '1.6',
  },
};

const h3 = css`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
`;

const regular = css`
  font-size: 14px;
  line-height: 20px;
`;

const variants = {
  h3,
  regular,
};

const Weight: { [key in TypographyWeight]: number } = {
  bold: 700,
  semibold: 600,
  medium: 500,
  normal: 400,
};

type Variant = 'h3' | 'regular';

interface TextProps {
  variant?: Variant;
  withGradient?: boolean;
  uppercase?: boolean;
  align?: string;
  color?: TColor;
  wrap?: boolean;
}

const TextBase = styled(View)<TextProps>`
  ${({ variant = 'regular' }) => variants[variant]};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color && Color[color]};
  white-space: ${({ wrap = true }) => (wrap ? 'pre-line' : 'nowrap')};
  ${({ trim }) =>
    trim &&
    `white-space:nowrap;overflow-wrap:normal;overflow:hidden;text-overflow: ellipsis; width: 100%`};
  strong {
    font-weight: bold;
  }
`;

export const Text = ({
  children,
  ...props
}: TextProps & {
  children: ReactNode;
} & ViewProps) => {
  return <TextBase {...props}>{children}</TextBase>;
};

const Typography: FC<TypographyProps> = styled(View)<TypographyProps>`
  overflow-wrap: break-word;
  white-space: ${({ $wrap = true }) => ($wrap ? 'pre-line' : 'nowrap')};
  ${({ size = 'regular' }) => Font[size]};
  font-weight: ${({ weight }) => weight && Weight[weight]};
  color: ${({ color = 'inherit' }) => color};
  text-align: ${({ align }) => align};
  ${({ trim }) =>
    trim &&
    `white-space:nowrap;overflow-wrap:normal;overflow:hidden;text-overflow: ellipsis; width: 100%`};
`;

export default Typography;
