import React, { CSSProperties, forwardRef, memo, useMemo } from 'react';
import { Tooltip } from '../Tooltip';
import { View } from '../View';
import stringToHslColor from './stringToColor';
import BaseSelect, { Props, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { UilAngleDown, UilTimes } from '@iconscout/react-unicons';
import { Typography } from '../Typography';
import { Label, Astrix } from './styles';
import { Colors } from '../styles/theme';

// eslint-disable-next-line react/display-name
const DropdownIndicator = memo((props: any) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      style={getStyles('clearIndicator', props)}
      {...restInnerProps}
      ref={ref}
    >
      <UilAngleDown color={Colors.gray5} />
    </div>
  );
});
// eslint-disable-next-line react/display-name
const ClearIndicator = memo((props: any) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      style={getStyles('clearIndicator', props)}
      {...restInnerProps}
      ref={ref}
    >
      <UilTimes color={Colors.dark} size={12} />
    </div>
  );
});

const Input = (props: any) => {
  return <components.Input {...props} autoComplete="nope" />;
};

const Option = (props: any) => {
  const { innerProps, ...rest } = props;
  innerProps['aria-label'] = `select-option-${rest.value}`;
  return <components.Option {...rest} innerProps={innerProps} />;
};

const MultiValueRemove = memo((props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <UilTimes color={Colors.textBlack} size={12} />
    </components.MultiValueRemove>
  );
});

export interface SelectProps extends Props {
  label: string;
  error?: string;
  isCreatable?: boolean;
  isAsync?: boolean;
  tooltip?: string;
}

export const Select = forwardRef((props: any, ref: any) => {
  const {
    label,
    error,
    styles: propsStyles,
    isColorTags,
    tooltip,
    isRequired,
    components,
    ...rest
  } = props;
  const styles = useMemo(
    () => ({
      control: (base: CSSProperties, { hasValue, isFocused }: any) => {
        return {
          ...base,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: error
            ? '#FF6040'
            : hasValue || isFocused
            ? '#34396833!important'
            : '#f7f7f7!important',
          boxShadow: 'none!important',
          backgroundColor: hasValue || isFocused ? '#fff' : '#f7f7f7',
          minHeight: 40,
          padding: '7px 12px',
          borderRadius: 12,
        };
      },
      container: (base: CSSProperties) => ({
        ...base,
        width: '100%',
        flex: 1,
      }),
      input: (base: CSSProperties) => ({
        ...base,
        fontSize: '14px',
        lineHeight: '1.35',
        color: '#202D4C',
        fontFamily: '"Basier Circle", sans-serif',
        padding: 0,
        marginLeft: 0,
      }),
      clearIndicator: (base: CSSProperties) => ({
        ...base,
        padding: 0,
        cursor: 'pointer',
      }),
      dropdownIndicator: (base: CSSProperties) => ({
        ...base,
        padding: 0,
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      placeholder: (base: CSSProperties) => ({
        ...base,
        fontSize: '14px',
        lineHeight: '1.35',
        color: '#a6abb7',
        margin: 0,
      }),
      option: (base: CSSProperties, state: any) => {
        return {
          ...base,
          cursor: 'pointer',
          color: '#333',
          padding: '12px',
          pointerEvents: state.isSelected ? 'none' : 'auto',
          backgroundColor:
            state.isFocused || state.isSelected
              ? '#f7f7f8!important'
              : 'transparent!important',
        };
      },
      valueContainer: (base: CSSProperties) => ({
        ...base,
        width: '100%',
        padding: 0,
      }),
      menu: (base: CSSProperties) => ({
        ...base,
        border: 'none',
        borderRadius: 6,
        boxShadow:
          '0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1), 0px 0px 1px rgba(15, 15, 15, 0.05)',
        backgroundColor: '#fff',
        zIndex: 1000,
      }),
      singleValue: (base: CSSProperties) => ({
        ...base,
        marginLeft: 0,
        fontSize: 14,
      }),
      multiValue: (base: CSSProperties, s: any) => {
        return {
          ...base,
          margin: 0,
          marginRight: 2,
          ...(isColorTags && {
            backgroundColor: stringToHslColor(s.data.value),
          }),
        };
      },
      multiValueLabel: (base: CSSProperties) => ({
        ...base,
        padding: 0,
        paddingRight: 3,
      }),
    }),
    [error]
  );
  const getComp = () => {
    if (props.isAsync && !props.isCreatable) {
      return AsyncSelect;
    }
    if (props.isAsync && props.isCreatable) {
      return AsyncCreatableSelect;
    }
    if (props.isCreatable) {
      return CreatableSelect;
    }
    return BaseSelect;
  };
  const Comp: any = getComp();
  return (
    <Label name={props.name as string}>
      {label && (
        <View row centerV marginB={8}>
          {isRequired && <Astrix>*</Astrix>}
          <Typography weight="medium" size="xs">
            {label}
          </Typography>
          {tooltip && <Tooltip message={tooltip} data-place="right" />}
        </View>
      )}
      <Comp
        styles={{ ...styles, ...propsStyles }}
        isClearable={false}
        isSearchable
        {...rest}
        inputId={`select-${props.name}`}
        components={{
          Input,
          Option,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
          ...components,
        }}
        ref={ref}
      />
      {error && (
        <Typography paddingV={8} size="xxs" color={Colors.error}>
          {error}
        </Typography>
      )}
    </Label>
  );
});

export default Select;
