import { compose, join, map, split } from 'rambda';
import React, { FC } from 'react';
import { AvatarProps, getInitials } from './share';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { Colors } from '../styles/theme';

type AvatarSize =
  | 'tiny'
  | 'xxs'
  | 'xs2'
  | 'xs'
  | 'sm'
  | 'regular'
  | 'xl'
  | 'xxl';

const Size: Record<AvatarSize, Record<string, string | number>> = {
  tiny: {
    width: 16,
    height: 16,
    'font-size': 20,
    'line-height': '1.2',
  },
  sm: {
    width: 32,
    height: 32,
    'font-size': 17,
    'line-height': '1.2',
  },
  xs: {
    width: 36,
    height: 36,
    'font-size': 16,
    'line-height': '1.2',
  },
  xs2: {
    width: 28,
    height: 28,
    'font-size': 20,
    'line-height': '1.2',
  },
  xxs: {
    width: 24,
    height: 24,
    'font-size': 14,
    'line-height': '20px',
  },
  regular: {
    width: 40,
    height: 40,
    'font-size': 20,
    'line-height': '1.2',
  },
  xl: {
    width: 44,
    height: 44,
    'font-size': 22,
    'line-height': '1.2',
  },
  xxl: {
    width: 64,
    height: 64,
    'font-size': 34,
    'line-height': '1.2',
  },
};

const Img = styled.img`
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    background-image: url(${({ src }) => src});
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: 0;
  }
`;

const Type = {
  square: {
    borderRadius: 0,
  },
  round: {
    borderRadius: '50%',
  },

  roundCorner: {
    borderRadius: '16px',
  },
};

const getSize = ({ size = 'regular' }: AvatarProps) => Size[size];
const getType = ({ type = 'round' }: AvatarProps) => Type[type];
const AvatarWrapper = styled.div<AvatarProps>`
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  transition: background-color 0.15s ease-in-out;
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  ${getSize}
  ${getType}
  ${({ border }) => border && `border: 1px solid ${border}`}
`;

const colors = [
  '#00AA55',
  '#009FD4',
  '#B381B3',
  '#939393',
  '#E3BC00',
  '#D47500',
  '#DC2A2A',
];

const numberFromText = compose(
  parseInt,
  join(''),
  map((char) => char.charCodeAt(0)),
  split('')
);

const Avatar = ({
  name = '',
  src,
  textColor = Colors.white,
  icon,
  color,
  size = 'regular',
  ...props
}: AvatarProps & { className?: string }) => {
  const backgroundColor = color || '#286DF3';
  const renderBody = () => {
    if (icon) {
      return icon;
    }
    if (src) {
      return <Img src={src} alt={name} />;
    }
    if (name) {
      const initials = getInitials(name).toUpperCase();
      return (
        <Typography size="inherit" weight="medium" color={textColor}>
          {initials}
        </Typography>
      );
    }
    return null;
  };
  return (
    <AvatarWrapper
      {...props}
      size={size}
      color={src ? Colors.transparent : backgroundColor}
      textColor={textColor}
    >
      {renderBody()}
    </AvatarWrapper>
  );
};

const C: FC<AvatarProps> = Avatar;

export default C;
