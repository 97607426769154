import { map } from 'rambda';
import { createContext } from 'react';
import { createContextualCan, useAbility as useBaseAbility } from '@casl/react';
import {
  Ability,
  AbilityBuilder,
  createAliasResolver,
  defineAbility,
} from '@casl/ability';
import { UserEntity } from 'types/user';

type AppAbility = Ability<[Actions, any]>;
export const AbilityContext = createContext({} as AppAbility);
export const Can = createContextualCan(AbilityContext.Consumer);
type Actions =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'manage'
  | 'can'
  | 'cant';
const resolveAction = createAliasResolver({
  can: 'manage',
  all_records: 'manage',
});
export const ability = defineAbility<AppAbility>(
  (can, cannot) => {
    can('manage', 'all');
  },
  {
    resolveAction,
  }
);

export const updateAbility = (entity: UserEntity) => {
  if (entity.userRole === 'owner') {
    const { can, rules } = new AbilityBuilder<AppAbility>(Ability);
    can('manage', 'all');
    ability.update(rules);
    return;
  }
  if (entity.userRole === 'accountant') {
    const { can, cannot, rules } = new AbilityBuilder<AppAbility>(Ability);
    can('manage', 'all');
    cannot('can', 'bank_accounts:new');
    cannot('can', 'transactions:new');
    cannot('can', 'incoming_bills:upload');
    cannot('can', 'team_access:manage');
    cannot('can', 'entities:info');
    ability.update(rules);
    return;
  }
  const rules = map(({ subject, action }) => {
    return {
      subject,
      action: 'can' as Actions,
      inverted: action === 'cant',
    };
  }, entity.teamMemberAccessRights.permissions);

  ability.update([{ subject: 'all', action: 'manage' }, ...rules]);
};

export const useAbility = () => useBaseAbility<AppAbility>(AbilityContext);
