import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react';
import { pick } from 'rambda';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IMaskMixin } from '@mirco312312/react-imask';
import { Tooltip } from '../Tooltip';
import { View } from '../View';
import { Typography } from '../Typography';
import { Astrix, Control, Label, StyledInput, Textarea } from './styles';
import { Colors } from '../styles/theme';

export interface InputBaseProps {
  label?: string;
  error?: string;
  addon?: () => ReactNode;
  inputSize?: 'sm' | 'md' | 'xl';
  postfix?: () => ReactNode;
  labelWidth?: number;
  wide?: boolean;
  mask?: any;
  signed?: boolean;
  radix?: string;
  scale?: number;
  onAccept?: any;
  commit?: any;
  tooltip?: string;
  isRequired?: boolean;
  inputRef?: any;
}

export interface InputProps
  extends InputBaseProps,
    InputHTMLAttributes<HTMLInputElement> {
  textarea?: undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
}
export interface TextareaProps
  extends InputBaseProps,
    TextareaHTMLAttributes<HTMLTextAreaElement> {
  textarea: true;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function isTextarea(props: any): props is TextareaProps {
  return props.textarea !== undefined;
}

export const MaskedInput = IMaskMixin(({ inputRef, textarea, ...props }: any) =>
  textarea ? (
    <Textarea {...props} ref={inputRef} />
  ) : (
    <StyledInput {...props} ref={inputRef} />
  )
);

// eslint-disable-next-line react/display-name
export const Input = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TextareaProps | InputProps
>((props: TextareaProps | InputProps, ref) => {
  const {
    label,
    mask,
    postfix,
    error,
    value,
    commit,
    onAccept,
    tooltip,
    isRequired,
    inputRef,
  } = props;
  const inputProps = pick(
    [
      'name',
      'label',
      'onChange',
      'onBlur',
      'onFocus',
      'defaultValue',
      'placeholder',
      'readOnly',
      'type',
      'rows',
      'maxLength',
      'minLength',
      'max',
      'min',
      'value',
      'data-id',
    ],
    props
  ) as any;

  const render = () => {
    if (mask) {
      return (
        <MaskedInput
          mask={mask}
          required
          error={error}
          onAccept={onAccept}
          commit={commit}
          $isInvalid={Boolean(error)}
          textarea={isTextarea(props)}
          inputRef={inputRef}
          {...inputProps}
        />
      );
    }
    if (isTextarea(props)) {
      return (
        <Textarea
          ref={ref}
          required
          $isInvalid={Boolean(error)}
          {...inputProps}
          {...(value !== undefined && { value: value ?? '' })}
        />
      );
    }
    return (
      <StyledInput
        ref={ref}
        required
        $isInvalid={Boolean(error)}
        {...(value !== undefined && { value: value ?? '' })}
        {...inputProps}
      />
    );
  };
  return (
    <Label>
      {label && (
        <View row centerV marginB={8}>
          {isRequired && <Astrix>*</Astrix>}
          <Typography
            color={props.readOnly ? Colors.monocolor40 : Colors.monocolor100}
            weight="medium"
            size="xs"
          >
            {label}
          </Typography>
          {tooltip && <Tooltip message={tooltip} data-place="right" />}
        </View>
      )}
      <Control row={Boolean(postfix)} centerV flex={1}>
        {render()}
        {postfix && postfix()}
      </Control>
      {error && (
        <Typography paddingV={8} size="xxs" color={Colors.error}>
          {error}
        </Typography>
      )}
    </Label>
  );
});

export default Input;
