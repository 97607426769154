import React, { ChangeEvent } from 'react';
import { Colors } from '..';
import { Typography } from '../Typography';
import { Item, List } from './styles';

const RadioItem = ({
  label,
  value,
  checked,
  onChange,
  itemWidth,
  radio = true,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  value: string;
  checked: boolean;
  itemWidth?: string;
  radio?: boolean;
}) => (
  <Item $checked={checked} $width={itemWidth}>
    <input
      type={radio ? 'radio' : 'checkbox'}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <Typography
      paddingV={12}
      weight="medium"
      color={checked ? Colors.white : Colors.gray3}
    >
      {label}
    </Typography>
  </Item>
);

interface BaseRadioProps {
  options: RadioOption[];
  itemWidth?: string;
  radio?: boolean;
}

interface RadioOption {
  value: string;
  label: string;
}

interface SingleRadioProps extends BaseRadioProps {
  value: string;
  onChange: (val: string) => void;
}
interface MultipleRadioProps extends BaseRadioProps {
  multiple: true;
  value: string[];
  onChange: (val: string[]) => void;
}

function isMultiple(props: any): props is MultipleRadioProps {
  return props.multiple !== undefined;
}

export type RadioGroupProps = MultipleRadioProps | SingleRadioProps;

export const RadioGroup = (props: RadioGroupProps) => {
  const { options, itemWidth, radio } = props;
  const isChecked = (val: string) =>
    isMultiple(props) ? props.value.includes(val) : props.value === val;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value: itemValue } = e.target;
    if (isMultiple(props)) {
      const { value, onChange } = props;
      checked
        ? onChange([...value, itemValue])
        : onChange(value.filter((v) => v !== itemValue));
    } else {
      const { onChange } = props;
      onChange(checked ? itemValue : '');
    }
  };
  const renderItem = ({ value, label }: RadioOption) => {
    return (
      <RadioItem
        radio={radio}
        itemWidth={itemWidth}
        key={value}
        label={label}
        value={value}
        onChange={handleChange}
        checked={isChecked(value)}
      />
    );
  };
  return <List>{options.map(renderItem)}</List>;
};
