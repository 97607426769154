export enum Spacing {
  xs = 8,
  sm = 16,
  md = 32,
  lg = 64,
}
export enum Colors {
  white = '#ffffff',
  whitex95 = '#ffffffeb',
  whitex50 = '#ffffff80',
  dark = '#202D4C',
  gray1 = '#798194',
  gray1x50 = '#33333380',
  gray2 = '#A6ABB7',
  gray3 = '#AFAEAC',
  gray4 = '#e9eaee',
  gray5 = '#34396833',
  gray5x85 = '#f4f4f4D9',
  gray6 = '#fbfbfb',
  gray7 = '#f7f7f7',
  red = '#FF6040',
  orange = '#FFBE3D',
  yellow = '#FFBE3D',
  green = '#2DCA8C',
  green10 = '#E5FFEE',
  green1 = '#2DCA8C',
  green2 = '#2DCA8C',
  green3 = '#2DCA8C',
  blue = '#286DF3',
  blue10 = '#e9f0fe',
  purple1 = '#9B51E0',
  purple2 = '#BB6BD9',
  mainText = '#0A1E32',
  textBlack = '#202D4C',
  textGray = '#798194',
  lightGray = '#34396899',
  superLightGray = '#37352f33',
  transparent = 'transparent',
  accent = '#286DF3',
  alert = '#FF6040',
  error = '#FF6040',
  disabledBtn = '#15151514',
  divider = '#3333331a',
  tint = '#2121210A',
  success = '#2DCA8C',
  btnHover = '#0267ff',
  surfaceOverlay = '#21212114',
  action = '#286DF3',
  monocolor100 = '#202d4c',
  monocolor60 = '#798194',
  monocolor40 = '#a6abb7',
  monocolor10 = '#e9eaed',
  monocolor04 = '#f6f7f8',
}

export const Color = {
  monocolor100: '#202d4c',
  monocolor60: '#798194',
  monocolor40: '#a6abb7',
  monocolor10: '#e9eaed',
  monocolor04: '#f6f7f8',
};

export type TColor = keyof typeof Color;

export const theme = {
  spacings: Spacing,
  colors: Colors,
  padding: 152,
  columns: 23,
  currentPoint: 'b1366',
  dashboardBackground: Colors.gray7,
  backgroundColor: Colors.gray7,
};
