import formatLastSync from 'helpers/formatLastSync';
import { action, makeAutoObservable, observable, reaction } from 'mobx';
import { forEach } from 'rambda';
import { RootStore } from 'store';

class NotificationsStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      setNotification: action,
    });
    reaction(
      () => this.rootStore.accountsStore.affectedAccounts,
      () => {
        forEach(({ affected, bank, id }) => {
          this.setNotification(id, {
            type: 'reconnect_bank',
            name: bank,
            id,
            affected,
          });
        }, this.rootStore.accountsStore.affectedAccounts);
      }
    );
    reaction(
      () => this.rootStore.accountsStore.awaitingConnections,
      () => {
        forEach(({ connectionName, status, id }) => {
          this.setNotification(id, {
            type: status,
            name: connectionName,
            id,
          });
        }, this.rootStore.accountsStore.awaitingConnections);
      }
    );
    reaction(
      () => this.rootStore.accountsStore.activeConnections,
      () => {
        forEach(({ connectionName, status, id }) => {
          if (this.notifications.has(id)) {
            this.setNotification(id, {
              type: status,
              name: connectionName,
              id,
            });
          }
        }, this.rootStore.accountsStore.activeConnections);
      }
    );
    reaction(
      () => this.rootStore.accountsStore.problemConnections,
      () => {
        forEach(({ connectionName, problemReason, id }) => {
          if (this.notifications.has(id)) {
            this.setNotification(id, {
              type: problemReason,
              name: connectionName,
              id,
            });
          }
        }, this.rootStore.accountsStore.problemConnections);
      }
    );
    reaction(
      () => this.rootStore.accountsStore.outdatedAccounts,
      () => {
        forEach(({ bank, lastSyncAt, id, affected }) => {
          if (this.notifications.has(id) && !affected) {
            this.setNotification(id, {
              type: 'update_manually',
              name: bank,
              time: formatLastSync(lastSyncAt).humanRead,
              id,
            });
          }
        }, this.rootStore.accountsStore.outdatedAccounts);
      }
    );
  }
  setNotification = (id: string, data: any) => {
    this.notifications.set(id, data);
  };
  notifications = observable.map({}, { deep: false });
  hiddenNotifications: string[] = [];
  hideNotification = (id: string) => this.notifications.delete(id);
}

export default NotificationsStore;
