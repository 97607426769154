import React, { ReactNode } from 'react';
import ModalFooter from './ModalFooter';
import { SimpleDialog } from './styles';
import { View } from '../View';
import ModalContainer from './ModalContainer';

interface Props {
  renderFooter?: () => ReactNode;
  children: ReactNode;
  onClose?: () => void;
  lockScroll?: boolean;
  width?: string;
}

const Modal = ({
  lockScroll,
  onClose,
  renderFooter,
  children,
  width = '420px',
}: Props) => {
  return (
    <ModalContainer width={width} onClose={onClose} lockScroll={lockScroll}>
      <SimpleDialog>
        {children}
        {renderFooter && renderFooter()}
      </SimpleDialog>
    </ModalContainer>
  );
};

Modal.Footer = ModalFooter;

export default Modal;
