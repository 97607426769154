import { UilInfoCircle } from '@iconscout/react-unicons';
import React, { ReactNode } from 'react';
import { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';
import { Colors } from '../styles/theme';

const Hint = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const Tooltip = ({
  children,
  message,
  ...props
}: {
  children?: ReactNode;
  message: string;
} & TooltipProps) => {
  return (
    <Hint data-tip={message} data-effect="solid" data-place="left" {...props}>
      {children || <UilInfoCircle size={20} color={Colors.lightGray} />}
    </Hint>
  );
};

export default Tooltip;
