import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/theme';

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: block;
  width: 36px;
  height: 20px;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
`;

const LabelText = styled.div`
  margin-right: auto;
`;

const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
  &:checked {
    & ~ div:nth-child(2) {
      background-color: ${Colors.green1};
    }
    & ~ div:last-child {
      transform: translateX(16px);
    }
  }
`;

const Body = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: ${Colors.gray3};
  transition: 0.15s cubic-bezier(0.15, 0.5, 0.5, 1);
`;
const Handle = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${Colors.white};
  left: 2px;
  top: 2px;
  transition: transform 300ms cubic-bezier(0.15, 0.5, 0.5, 1) 0s;
`;

const Switch = ({
  children,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Label>
      <LabelText>{children}</LabelText>
      <Wrapper>
        <Input type="checkbox" {...props} />
        <Body />
        <Handle />
      </Wrapper>
    </Label>
  );
};

export default Switch;
