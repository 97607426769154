import React, { ChangeEvent, forwardRef, ReactNode } from 'react';
import { Typography } from '../Typography';
import { Checkbox } from '../Checkbox';
import { Label } from './styles';
import { View } from '../View';
import { Colors } from '../styles/theme';

export interface RadioOption {
  label: ReactNode;
  value: string;
}

export interface RadioProps {
  options: RadioOption[];
  value?: string;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  name?: string;
}

const Radio = forwardRef(
  ({ options, value, label, ...inputProps }: RadioProps, ref: any) => {
    const renderItem = ({
      value: optionValue,
      label: optionLabel,
    }: RadioOption) => {
      return (
        <Label key={optionValue}>
          <input type="radio" ref={ref} value={optionValue} {...inputProps} />
          <Checkbox checked={optionValue === value} />
          <Typography marginL={16}>{optionLabel}</Typography>
        </Label>
      );
    };
    return (
      <View paddingV={16} marginB={12}>
        {label && (
          <Typography marginB={12} size="xs" color={Colors.gray4}>
            {label}
          </Typography>
        )}
        <View row>{options.map(renderItem)}</View>
      </View>
    );
  }
);

export default Radio;
