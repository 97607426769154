import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { Colors } from '../styles/theme';

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid #e9eaed;
  background-repeat: no-repeat;
  background-position: center center;
  transform: translateY(2px);
`;
const Label = styled.label`
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  align-items: flex-start;
  svg {
    flex-shrink: 0;
  }
  input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    &:checked + ${Checkbox} {
      border-color: ${Colors.dark};
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjcyMDA1IDAuNzg5ODE2TDMuNDMwMDUgNS4wODk4MkwxLjc4MDA1IDMuNDM5ODJDMS42OTA0IDMuMzM1MTMgMS41ODAwOSAzLjI1MDExIDEuNDU2MDIgMy4xOTAwOUMxLjMzMTk2IDMuMTMwMDcgMS4xOTY4MiAzLjA5NjM0IDEuMDU5MSAzLjA5MTAyQzAuOTIxMzg2IDMuMDg1NyAwLjc4NDA1MyAzLjEwODkgMC42NTU3MjggMy4xNTkxOEMwLjUyNzQwMyAzLjIwOTQ1IDAuNDEwODU2IDMuMjg1NzEgMC4zMTM0MDEgMy4zODMxN0MwLjIxNTk0NiAzLjQ4MDYyIDAuMTM5Njg5IDMuNTk3MTcgMC4wODk0MTQgMy43MjU0OUMwLjAzOTEzOTQgMy44NTM4MiAwLjAxNTkzMzUgMy45OTExNSAwLjAyMTI1MyA0LjEyODg3QzAuMDI2NTcyNSA0LjI2NjU5IDAuMDYwMzAyNiA0LjQwMTcyIDAuMTIwMzI2IDQuNTI1NzlDMC4xODAzNDkgNC42NDk4NSAwLjI2NTM3IDQuNzYwMTcgMC4zNzAwNTEgNC44NDk4MkwyLjcyMDA1IDcuMjA5ODJDMi44MTM0OSA3LjMwMjUgMi45MjQzMSA3LjM3NTgyIDMuMDQ2MTQgNy40MjU1OUMzLjE2Nzk4IDcuNDc1MzUgMy4yOTg0NSA3LjUwMDU4IDMuNDMwMDUgNy40OTk4MkMzLjY5MjM5IDcuNDk4NzEgMy45NDM3OSA3LjM5NDU2IDQuMTMwMDUgNy4yMDk4Mkw5LjEzMDA1IDIuMjA5ODJDOS4yMjM3OCAyLjExNjg1IDkuMjk4MTcgMi4wMDYyNSA5LjM0ODk0IDEuODg0MzlDOS4zOTk3MSAxLjc2MjUzIDkuNDI1ODUgMS42MzE4MyA5LjQyNTg1IDEuNDk5ODJDOS40MjU4NSAxLjM2NzggOS4zOTk3MSAxLjIzNzEgOS4zNDg5NCAxLjExNTI0QzkuMjk4MTcgMC45OTMzOCA5LjIyMzc4IDAuODgyNzc5IDkuMTMwMDUgMC43ODk4MTZDOC45NDI2OSAwLjYwMzU2NSA4LjY4OTI0IDAuNDk5MDIzIDguNDI1MDUgMC40OTkwMjNDOC4xNjA4NyAwLjQ5OTAyMyA3LjkwNzQxIDAuNjAzNTY1IDcuNzIwMDUgMC43ODk4MTZaIiBmaWxsPSIjMjAyRDRDIi8+Cjwvc3ZnPgo=');
    }
  }
`;

const Checkbox2 = forwardRef(
  (
    {
      children,
      ...props
    }: { children: ReactNode } & InputHTMLAttributes<HTMLInputElement>,
    ref: any
  ) => {
    return (
      <Label>
        <input ref={ref} type="checkbox" {...props} />
        <Checkbox />
        <Typography marginL={15}>{children}</Typography>
      </Label>
    );
  }
);

export default Checkbox2;
