import { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import {
  extractPaddingValues,
  extractMarginValues,
  extractFlexStyle,
  extractAlignmentsValues,
} from '../commons/modifiers';

export interface ViewProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  margin?: number | 'auto';
  marginL?: number | 'auto';
  marginT?: number | 'auto';
  marginR?: number | 'auto';
  marginB?: number | 'auto';
  marginH?: number | 'auto';
  marginV?: number | 'auto';
  padding?: number;
  paddingL?: number;
  paddingT?: number;
  paddingR?: number;
  paddingB?: number;
  paddingH?: number;
  paddingV?: number;
  reverse?: boolean;
  flex?: number | string;
  flexG?: number;
  flexS?: number;
  column?: boolean;
  row?: boolean;
  left?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
  center?: boolean;
  centerV?: boolean;
  centerH?: boolean;
  base?: boolean;
  spread?: boolean;
  className?: string;
  divider?: 'top' | 'bottom';
  as?: keyof JSX.IntrinsicElements;
  $wrap?: boolean;
  dividerBottom?: boolean;
  dividerTop?: boolean;
  $dividerTop?: boolean;
  $dividerBottom?: boolean;
  trim?: boolean;
}

const View: FC<ViewProps> = styled.div<ViewProps>`
  ${(props) => extractPaddingValues(props)};
  ${(props) => extractMarginValues(props)};
  ${(props) => extractFlexStyle(props)};
  ${(props) => extractAlignmentsValues(props)};
  ${({ divider }) =>
    divider &&
    `box-shadow: inset 0 ${
      divider === 'top' ? '1px' : '-1px'
    } 0 0 rgba(51, 51, 51, 0.1)`};
  &:not(:last-child) {
    ${({ $dividerTop, $dividerBottom, dividerBottom, dividerTop }) => {
      const shadow = [];
      if ($dividerTop || dividerTop) {
        shadow.push('inset 0 1px 0 0 rgba(235, 235, 235, 0.75)');
      }
      if ($dividerBottom || dividerBottom) {
        shadow.push('inset 0 -1px 0 0 rgba(235, 235, 235, 0.75)');
      }
      return `box-shadow: ${shadow.join(',')};`;
    }}
  }
  ${({ $dividerTop, dividerTop }) => {
    const shadow = [];
    if ($dividerTop || dividerTop) {
      shadow.push('inset 0 1px 0 0 rgba(235, 235, 235, 0.75)');
    }
    return `box-shadow: ${shadow.join(',')};`;
  }}
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'};
  ${({ trim }) =>
    trim &&
    `white-space:nowrap;overflow-wrap:normal;overflow:hidden;text-overflow: ellipsis; width: 100%`};
`;

export default View;
