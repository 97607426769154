import api from 'api/index';
import { AxiosPromise } from 'axios';
import { useQuery } from 'react-query';
import { PersonalData, Takeoff, User, UserAddress } from 'types/user';

export const routes = {
  get basePath() {
    return '/users';
  },
  takeOffPath() {
    return '/takeoff';
  },
  passwordPath() {
    return [this.basePath, 'password'].join('/');
  },
  avatarPath() {
    return [this.basePath, 'userpic'].join('/');
  },
  signaturePath() {
    return [this.basePath, 'personal_signature'].join('/');
  },
  twoFAPath() {
    return [this.basePath, '2fa'].join('/');
  },
  newTwoFAPath() {
    return [this.twoFAPath(), 'new'].join('/');
  },
  addressPath() {
    return [this.basePath, 'address'].join('/');
  },
  restorePasswordPath() {
    return [this.basePath, 'password'].join('/');
  },
  resetPasswordPath() {
    return [this.basePath, 'password', 'reset'].join('/');
  },
  personalAccountPath() {
    return [this.basePath, 'personal_account'].join('/');
  },
  emailCheckPath() {
    return [this.basePath, 'email_check'].join('/');
  },
  checkTwoFaEmailPath() {
    return [this.twoFAPath(), 'is_email_reachable'].join('/');
  },
  verifyEmailPath() {
    return [this.twoFAPath(), 'verify_email_reachibility'].join('/');
  },
  toggleTwoFaPath() {
    return [this.twoFAPath(), 'toggle'].join('/');
  },
};

const userApi = {
  async signUp(body: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) {
    const { data } = await api.post<{ user: User; token: string }>(
      routes.basePath,
      body
    );
    return data;
  },
  async signIn(body: { login: string; password: string }) {
    const { data } = await api.put<{
      code: string;
      id: string;
      token: string;
      is2fa: boolean;
    }>(routes.basePath, body);
    return data;
  },
  getUser(): AxiosPromise<User> {
    return api(routes.basePath);
  },
  updatePersonalData(data: Partial<PersonalData>) {
    return api.patch<{ user: User; token: string }>(routes.basePath, data);
  },
  getEntities(): AxiosPromise<Takeoff> {
    return api(routes.takeOffPath());
  },
  setPassword(password: string): AxiosPromise<User> {
    return api.post(routes.passwordPath(), { password });
  },
  updatePassword(data: { oldPassword: string; newPassword: string }) {
    return api.put(routes.passwordPath(), data);
  },
  uploadAvatar(data: FormData): AxiosPromise<void> {
    return api.post(routes.avatarPath(), data);
  },
  saveSignature(data: {
    encodedImg: string;
  }): AxiosPromise<{ original: string; encodedOriginal: string }> {
    return api.post(routes.signaturePath(), data);
  },
  getSignature(): AxiosPromise<{ original: string; encodedOriginal: string }> {
    return api(routes.signaturePath());
  },
  twoFa(body: { code: string; id: string }) {
    return api.post(routes.twoFAPath(), body);
  },
  requestTwoFa() {
    return api.post<{ code: string; id: string }>(routes.newTwoFAPath());
  },
  async updateAddress(body: UserAddress) {
    const { data } = await api.put(routes.addressPath(), body);
    return data;
  },
  restorePassword(email: string) {
    return api.get(routes.restorePasswordPath(), { params: { email } });
  },
  resetPassword(body: { token: string; newPassword: string }) {
    return api.post(routes.resetPasswordPath(), body);
  },
  async getPersonalAccount() {
    const { data } = await api.get(routes.personalAccountPath());
    return data;
  },
  async addPersonalAccount(body: { bankId: string; accountId: string }) {
    const { data } = await api.post(routes.personalAccountPath(), body);
    return data;
  },
  async updatePersonalAccount(body: { bankId: string; accountId: string }) {
    const { data } = await api.put(routes.personalAccountPath(), body);
    return data;
  },
  async checkEmail(email: string) {
    const { data } = await api.get(routes.emailCheckPath(), {
      params: { email },
    });
    return data;
  },
  async checkTwoFaEmail(params: { email: string; firstName: string }) {
    const { data } = await api.get(routes.checkTwoFaEmailPath(), { params });
    return data;
  },
  async toggleTwoFa() {
    const { data } = await api.get(routes.toggleTwoFaPath());
    return data;
  },
  async confirmTwoFa(body: { id: string; code: string }) {
    const { data } = await api.post(routes.toggleTwoFaPath(), body);
    return data;
  },
  async verifyEmail(body: { email: string; code: string }) {
    const { data } = await api.post(routes.verifyEmailPath(), body);
    return data;
  },
};

export const usePersonalAccount = () =>
  useQuery('personalAccount', userApi.getPersonalAccount, {
    staleTime: Infinity,
  });

export default userApi;
