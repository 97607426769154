export enum UserStatus {
  WAITING_FOR_LOGIN_VERIFICATION = 'waiting_for_login_verification',
  CREATE_WITHOUT_PASSWORD = 'created_without_password',
  ACTIVE = 'active',
}

export enum UserSalutation {
  MR = 'MR',
  MS = 'MS',
}
