import React, { ButtonHTMLAttributes } from 'react';
import { View } from '../View';
import { Typography } from '../Typography';
import { Button, ButtonProps } from '../Button';

import { Footer } from './styles';

interface FooterProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClose?: () => void;
  confirmText?: string;
  isLoading?: boolean;
  closeText?: string;
}

const ModalFooter = ({
  onClose,
  disabled = false,
  isLoading,
  confirmText,
  closeText = 'Cancel',
  ...props
}: FooterProps & ButtonProps) => {
  return (
    <Footer padding={24} row spread>
      {onClose && (
        <Button btnTheme="light" onClick={onClose}>
          <Typography>{closeText}</Typography>
        </Button>
      )}
      {confirmText && (
        <View marginL="auto" marginR={onClose ? 0 : 'auto'}>
          <Button
            type={props.form ? 'submit' : 'button'}
            {...props}
            disabled={disabled}
            loading={isLoading}
            aria-label="submit-modal"
          >
            {confirmText}
          </Button>
        </View>
      )}
    </Footer>
  );
};

export default ModalFooter;
