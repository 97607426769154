import axios, { AxiosError, AxiosTransformer } from 'axios';
import { AUTH_TOKEN, LANGUAGE_KEY } from 'const';
import { decamelizeKeys, camelizeKeys, camelize } from 'humps';
import i18n from 'i18n';
import { store } from 'store/init';
import { path, pathOr, last } from 'rambda';
import { ReactText } from 'react';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { REACT_APP_BASE_URL } from 'utils/env';

let toastId: ReactText;

const defaultTransformers = (
  transformRequest?: AxiosTransformer | AxiosTransformer[]
): AxiosTransformer[] => {
  if (!transformRequest) {
    return [];
  }
  if (transformRequest instanceof Array) {
    return transformRequest;
  }

  return [transformRequest];
};

const token = localStorage.getItem(AUTH_TOKEN);

const api = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    'Accept-Language': localStorage.getItem(LANGUAGE_KEY) || 'en',
    ...(token && {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    }),
  },
  transformRequest: [
    (data: any): unknown => {
      if (data instanceof FormData) {
        return data;
      }
      return decamelizeKeys(data, { split: /(?=[A-Z0-9])/ });
    },
    ...defaultTransformers(axios.defaults.transformRequest),
  ],
  transformResponse: [
    ...defaultTransformers(axios.defaults.transformResponse),
    (data: any): unknown => {
      return camelizeKeys(data);
    },
  ],
  paramsSerializer: (params) =>
    qs.stringify(decamelizeKeys(params), {
      skipEmptyString: true,
      skipNull: true,
    }),
});

api.interceptors.response.use(
  async function (config) {
    return config;
  },
  function (error: AxiosError) {
    if (!error.response) {
      return Promise.reject(error);
    }
    const {
      response: { status },
    } = error;

    if (status === 451) return Promise.reject(error);

    if (status === 401) {
      return store.busStore.setAuth(false);
    }
    if (status === 422) {
      if (!toast.isActive(toastId)) {
        const errorLoc = pathOr<string[]>(
          [],
          'response.data.detail.0.loc'.split('.')
        )(error);
        const field = last(errorLoc);
        const errorMessage = path<string>(
          'response.data.detail.0.msg'.split('.')
        )(error);
        toastId = toast.error(`${field}: ${errorMessage}`);
      }
      return Promise.reject(error);
    }
    if (status >= 500 && !toast.isActive(toastId)) {
      toastId = toast.error(
        i18n.t('errors.somethingWentWrongTryRefreshingThePage')
      );
      return Promise.reject(error);
    }
    if (status >= 409 && !toast.isActive(toastId)) {
      toastId = toast.error(
        i18n.t(
          `errors.${camelize(
            pathOr(
              'errors.somethingWentWrongTryRefreshingThePage',
              'response.data.errorCode',
              error
            )
          )}`
        )
      );
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export function setLanguageHeader(language: string) {
  const storedLanguage = localStorage.getItem(LANGUAGE_KEY);
  api.defaults.headers.common['Accept-Language'] = language || storedLanguage;
  api.defaults.headers['Accept-Language'] = language || storedLanguage;
}

export function setTokenHeader(token: string) {
  const storedToken = localStorage.getItem('authToken');

  if (!token && !storedToken) {
    return;
  }

  api.defaults.headers.common.Authorization = `Bearer ${token || storedToken}`;
  api.defaults.headers.Authorization = `Bearer ${token || storedToken}`;
}

export function removeTokenHeader() {
  delete api.defaults.headers.common.Authorization;
  delete api.defaults.headers.Authorization;
}

export default api;
