import api from 'api/index';
import { EntityData } from 'types/entities';
import { AxiosPromise } from 'axios';

export const routes = {
  get basePath() {
    return `/entities`;
  },
  entityPath(id: string) {
    return `/entities/${id}`;
  },
};

const entityApi = {
  getEntities(): AxiosPromise<{ data: EntityData[] }> {
    return api(routes.basePath);
  },
  createEntity(data: Partial<EntityData>): AxiosPromise<EntityData> {
    return api.post(routes.basePath, data);
  },
  updateEntity(
    entityId: string,
    data: Partial<EntityData>
  ): AxiosPromise<EntityData> {
    return api.put(routes.entityPath(entityId), data);
  },
  uploadAvatar(entityId: string, data: FormData): AxiosPromise<EntityData> {
    return api.post(`${routes.entityPath(entityId)}/picture`, data);
  },
};

export default entityApi;
