import { store } from 'store/init';
import invariant from 'tiny-invariant';

const routes = {
  get basePath() {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return `/expense_reports/${activeEntity.relatedGemmsEntityType}/${activeEntity.relatedGemmsEntityId}`;
  },
  recognizeInvoicePath() {
    return [this.basePath, 'upload'].join('/');
  },
  expensePath(expenseId: string) {
    return [this.basePath, 'expenses', expenseId].join('/');
  },
  diemReferencePath() {
    return '/references/per_diem/de';
  },
  mileageReferencePath() {
    return '/references/mileage/de';
  },
  mileageExpensesPath() {
    return `${this.basePath}/mileage_trip`;
  },
  mileageExpensePath(expenseId: string) {
    return `${this.mileageExpensesPath()}/${expenseId}`;
  },
  diemExpensesPath() {
    return `${this.basePath}/per_diem`;
  },
  diemExpensePath(expenseId: string) {
    return `${this.diemExpensesPath()}/${expenseId}`;
  },
  lostExpensesPath() {
    return `${this.basePath}/without_receipt`;
  },
  lostExpensePath(expenseId: string) {
    return `${this.lostExpensesPath()}/${expenseId}`;
  },
  regularExpensesPath() {
    return `${this.basePath}/receipt`;
  },
  regularExpensePath(expenseId: string) {
    return `${this.regularExpensesPath()}/${expenseId}`;
  },
  documentsPath(expenseId: string) {
    return `${this.basePath}/expenses/${expenseId}/documents`;
  },
  replaceFilePath(expenseId: string) {
    return [this.regularExpensePath(expenseId), 'replace_file'].join('/');
  },
};

export default routes;
