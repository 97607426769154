import styled from 'styled-components';
import { Colors } from '../styles/theme';
export const Label = styled.label.attrs(({ name }: { name: string }) => ({
  'aria-label': `select-${name}`,
}))<{ name?: string }>`
  display: block;
  margin-bottom: 24px;
  width: 100%;
`;
export const Astrix = styled.div`
  position: absolute;
  color: ${Colors.red};
  font-size: 12px;
  font-weight: 500;
  transform: translateX(-8px);
`;
