import api from 'api/index';
import { Psd2Connection } from 'apps/accounts/types';
import { AxiosPromise } from 'axios';
import { Psd2Bank } from 'components/ConnectBankAccount';
import { store } from 'store/init';
import invariant from 'tiny-invariant';

class PSD2Api {
  async initFinapi() {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    api.post(`/entities/${activeEntity.relatedGemmsEntityId}/finapi`);
  }
  searchBank(search: string): AxiosPromise<{ data: Psd2Bank[] }> {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return api(
      `/entities/${activeEntity.relatedGemmsEntityId}/psd2/find_bank?is_supported=true&provider=finapi&search=${search}`
    );
  }
  initNewConnection(bankId: string): AxiosPromise<{ authLink: string }> {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return api.post(`/entities/${activeEntity.relatedGemmsEntityId}/psd2`, {
      provider: 'finapi',
      bankId,
    });
  }
  async fetchStatus() {
    const { activeEntity } = store.userStore;
    const { data } = await api.get<{ data: Psd2Connection[] }>(
      `/entities/${activeEntity?.relatedGemmsEntityId}/psd2`
    );
    return data.data;
  }
  revokePsd2Access() {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return api(
      `/dev/${activeEntity.relatedGemmsEntityType}/${activeEntity.relatedGemmsEntityId}/revoke_psd2_access`
    );
  }
  restartConnection(psd2ConnectionId: string) {
    const { activeEntity } = store.userStore;
    invariant(activeEntity, 'Not found active entity');
    return api.get(
      `/entities/${activeEntity.relatedGemmsEntityId}/psd2/${psd2ConnectionId}/auth`,
      {
        params: {
          force_reinit: true,
        },
      }
    );
  }
}

const psd2Api = new PSD2Api();

export default psd2Api;
