import styled from 'styled-components';
import { View } from '../View';
import { Colors } from '../styles/theme';

export const TopBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 56px;
  background-color: ${Colors.white};
  padding: 18px 0;
`;

export const Wrapper = styled.div<{ lockScroll?: boolean; blurred?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: ${({ lockScroll }) => (lockScroll ? 'hidden' : 'auto')};
  padding: 32px 50px;
  min-height: 100vh;
  z-index: 1000;
  background-color: ${Colors.monocolor100}33;
  & > div {
    display: flex;
    margin: auto;
  }
`;

export const Inner = styled.div<{ $width?: string }>`
  width: 100%;
  max-width: ${({ $width = '456px' }) => $width};
`;

export const Dialog = styled.div`
  margin: auto;
  width: 100%;
  border-radius: 16px;
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 1px rgba(15, 15, 15, 0.05), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 9px 24px rgba(15, 15, 15, 0.2);
`;

export const SimpleDialog = styled(Dialog)`
  padding: 36px;
`;

export const Footer = styled(View)`
  position: sticky;
  bottom: 0;
  margin-top: auto;
`;

export const CloseBtn = styled.button.attrs(() => ({
  type: 'button',
}))`
  position: absolute;
  padding: 16px;
  right: 0;
  top: 0;
`;
