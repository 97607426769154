import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';
import { Colors } from '../styles/theme';

type ButtonSize = 'sm' | 'md' | 'xl';
export type ButtonTheme =
  | 'primary'
  | 'light'
  | 'accent'
  | 'alert'
  | 'outline'
  | 'success';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  btnTheme?: ButtonTheme;
  loading?: boolean;
  block?: boolean;
}

const Size: Record<ButtonSize, Record<string, string | number>> = {
  sm: {
    height: 36,
    padding: '0 16px',
    lineHeight: '20px',
    fontSize: 14,
  },
  md: {
    height: 40,
    fontSize: 14,
    lineHeight: '20px',
    padding: '0 16px',
  },
  xl: {
    height: 56,
    padding: '0 24px',
  },
};

const Theme: Record<ButtonTheme, Record<string, string>> = {
  accent: {
    backgroundColor: Colors.blue,
  },
  primary: {
    backgroundColor: Colors.blue,
  },
  light: {
    backgroundColor: '#F7F7F7',
    color: Colors.dark,
  },
  alert: {
    backgroundColor: Colors.red,
  },
  outline: {
    backgroundColor: Colors.transparent,
    color: Colors.gray2,
    border: `1px solid ${Colors.gray4}`,
  },
  success: {
    backgroundColor: Colors.green1,
    color: Colors.white,
  },
};

const Hover: Record<ButtonTheme, string> = {
  primary: '#4786FF',
  alert: '#FF6040d4',
  accent: '#4786FF',
  outline: '',
  success: '',
  light: '#F7F7F7',
};

const getTheme = ({ btnTheme = 'primary' }: ButtonProps) => Theme[btnTheme];
const getSize = ({ size = 'md' }: ButtonProps) => Size[size];
const getHover = ({ btnTheme = 'primary' }: ButtonProps) => {
  return `&:hover {
    background-color: ${Hover[btnTheme]}
  }`;
};

const StyledBtn = styled.button<ButtonProps>`
  color: ${Colors.white};
  border-radius: 12px;
  font-family: inherit;
  font-size: inherit;
  min-width: 110px;
  font-weight: 500;
  white-space: nowrap;
  ${({ block }) => block && 'width: 100%'};
  transition: background-color 0.15s ease-in-out;
  ${getSize};
  ${getTheme};
  ${getHover};
  &:disabled {
    opacity: 0.5;
  }
  span {
    display: flex;
    align-content: center;
    justify-content: center;
    color: inherit;
    white-space: nowrap;
  }
`;

const Button = ({
  children,
  disabled = false,
  loading,
  type = 'button',
  ...props
}: ButtonProps) => {
  return (
    <StyledBtn disabled={disabled || loading} type={type} {...props}>
      {loading ? (
        <span>
          <Spinner size="sm" />
        </span>
      ) : (
        children
      )}
    </StyledBtn>
  );
};

export default Button;
