import {
  makeObservable,
  computed,
  flow,
  IObservableValue,
  observable,
  when,
} from 'mobx';
import statesApi from 'api/states';
import { States } from 'types/states';
import { AxiosResponse } from 'axios';
import { RootStore } from './index';

class StatesStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.busStore.isAuth,
      () => {
        this.fetchStates();
      }
    );
    makeObservable(this, {
      clientState: computed,
    });
  }
  public states: IObservableValue<States> = observable.box(
    {
      states: {},
      clientStates: {
        initFinApi: false,
      },
    },
    { deep: false }
  );

  public fetchStates = flow(function* (this: StatesStore) {
    const res: AxiosResponse<States> = yield statesApi.getState();
    this.states.set(res.data);
  }).bind(this);

  public saveState = flow(function* (
    this: StatesStore,
    stateId: string,
    state: any
  ) {
    const res: AxiosResponse<States> = yield statesApi.saveState(
      stateId,
      state
    );
    this.states.set(res.data);
    return res;
  }).bind(this);

  get clientState() {
    return this.states.get().clientStates;
  }
}

export default StatesStore;
