import expensesApi from 'apps/expenses/api';
import { DiemReference, Expense, ExpenseModel } from 'apps/expenses/types';
import {
  makeObservable,
  action,
  computed,
  observable,
  when,
  flow,
  autorun,
} from 'mobx';
import { any } from 'rambda';
import { QueryKey } from 'react-query';
import { RootStore } from 'store';

// TODO Better typings for expenses

export class ExpensesStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.userStore?.isAuth,
      () => {
        this.fetchDiemReference();
      }
    );
    makeObservable(this, {
      selectedExpense: observable.ref,
      isUploading: observable,
      selectExpense: action,
      addNewExpenseId: action,
      addUploadingFiles: action,
      removeUploadingFile: action,
      clearUploadingFiles: action,
      setUploading: action,
      setProcessingFile: action,
      clearNewExpenses: action,
      hasProcessingFiles: computed,
    });
    autorun(() => {
      this.queryKey.replace([
        this.entityType,
        this.rootStore.filtersStore.filtersQuery[this.entityType],
      ]);
    });
  }
  updateQuery = (query: QueryKey[]) => {
    this.queryKey.replace(query);
  };
  entityType = 'expenses';
  queryKey = observable.array<QueryKey>([]);
  public newExpenses = observable.array<string>([], { deep: false });
  public diemReferences = observable.array<DiemReference>([], {
    deep: false,
  });
  selectedExpense: Expense<ExpenseModel> | null = null;
  public isUploading = false;
  public uploadingFiles = observable.array<File>([], { deep: false });
  public processingFiles = observable.array<boolean>([], { deep: false });

  selectExpense = (expense: Expense<any> | null) => {
    this.selectedExpense = expense;
  };

  fetchDiemReference = flow(function* (this: ExpensesStore) {
    const data = yield expensesApi.fetchDiemReference();
    this.diemReferences.replace(data);
  });

  addNewExpenseId = (expenseId: string) => this.newExpenses.push(expenseId);

  addUploadingFiles = (files: File[]) => {
    const concatenatedFiles = this.uploadingFiles.concat(files);
    this.uploadingFiles.replace(concatenatedFiles);
  };

  removeUploadingFile = (file: File) => {
    this.uploadingFiles.remove(file);
  };

  clearUploadingFiles = () => this.uploadingFiles.clear();

  public setUploading = (uploading: boolean) => (this.isUploading = uploading);

  setProcessingFile = (idx: number, status: boolean) => {
    this.processingFiles[idx] = status;
  };

  clearNewExpenses = () => this.newExpenses.clear();

  get hasProcessingFiles() {
    return any(Boolean)(this.processingFiles);
  }
}
