import isOffer from 'apps/invoices/helpers/isOffer';
import { Invoice } from 'apps/invoices/types';
import { action, autorun, observable, makeObservable } from 'mobx';
import { QueryKey } from 'react-query';
import { RootStore } from 'store';

export class InvoicesStore {
  private rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      selectedInvoice: observable.ref,
      selectInvoice: action,
      updateQuery: action,
    });
    autorun(() => {
      this.updateQuery([
        this.entityType,
        this.rootStore.filtersStore.filtersQuery[this.entityType],
      ]);
    });
  }
  updateQuery = (query: QueryKey[]) => {
    this.queryKey.replace(query);
  };
  entityType = 'invoices';
  queryKey = observable.array<QueryKey>([]);
  selectedInvoice: Invoice | null = null;
  public selectInvoice = (invoice: Invoice | null) => {
    this.selectedInvoice = invoice;
    if (!invoice) {
      this.rootStore.modalsStore.closeModal('newInvoice');
      return;
    }
    this.rootStore.modalsStore.openModal('newInvoice', {
      isOffer: isOffer(invoice),
    });
  };
  closeInvoiceCard = () => {
    this.rootStore.modalsStore.closeModal('invoiceCard');
  };
}
