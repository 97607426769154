import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';
import { View } from '../View';
import { InputProps } from './Input';
import { Typography } from '../Typography';
import { Colors } from '../styles/theme';

export const Label = styled.label`
  display: block;
  margin-bottom: 24px;
`;

export const Textarea = styled(TextareaAutosize)<any>`
  width: 100%;
  background-color: ${({ value, defaultValue }) =>
    value || defaultValue ? Colors.transparent : Colors.gray7};
  font-size: 14px;
  line-height: 1.2;
  padding: 10px 12px;
  min-height: 40px;
  border-radius: 12px;
  font-family: inherit;
  color: ${Colors.textBlack};
  border: 1px solid
    ${({ $isInvalid, value, defaultValue }) => {
      if ($isInvalid) return `${Colors.alert}!important`;
      return value || defaultValue ? Colors.gray5 : Colors.gray7;
    }};
  &::placeholder {
    color: ${Colors.monocolor40};
  }
  &:focus {
    border-color: ${Colors.gray5};
    background-color: ${Colors.transparent};
  }
  &:placeholder-shown {
    border-color: ${Colors.transparent};
    background-color: ${Colors.gray7};
  }
  &:valid {
    border-color: ${Colors.gray5};
    background-color: ${Colors.transparent};
  }
  &:read-only {
    border-color: ${Colors.transparent};
    background-color: ${Colors.gray7};
  }
`;

export const Postfix = styled.div`
  width: 140px;
  text-align: right;
`;

export const StyledInput = styled.input<
  InputProps & { ref: any; $isInvalid?: boolean }
>`
  height: 40px;
  border-radius: 12px;
  width: 100%;
  font-size: 14px;
  padding: 0 12px;
  font-family: inherit;
  background-color: ${({ value, defaultValue }) =>
    value || defaultValue ? Colors.transparent : Colors.gray7};
  color: ${Colors.dark};
  border: 1px solid
    ${({ $isInvalid, value, defaultValue }) => {
      if ($isInvalid) return `${Colors.alert}!important`;
      return value || defaultValue ? Colors.gray5 : Colors.gray7;
    }};
  &::placeholder {
    color: ${Colors.lightGray};
  }
  &:focus {
    border-color: ${Colors.gray5};
    background-color: ${Colors.transparent};
  }
  &:placeholder-shown {
    border-color: ${Colors.transparent};
    background-color: ${Colors.gray7};
  }
  &:valid {
    border-color: ${Colors.gray5};
    background-color: ${Colors.transparent};
  }
  &:read-only {
    border-color: ${Colors.transparent};
    background-color: ${Colors.gray7};
  }
`;

export const Counter = styled(Typography)<{ $visible?: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`;

export const Control = styled(View)`
  align-self: flex-start;
  position: relative;
`;

export const Astrix = styled.div`
  position: absolute;
  color: ${Colors.red};
  font-size: 12px;
  font-weight: 500;
  transform: translateX(-8px);
`;
