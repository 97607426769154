import React, { ReactNode, useCallback, useRef } from 'react';
import useOnCloseByEsc from '../hooks/useOnCloseByEsc';
import useLockBodyScroll from '../hooks/useLockBodyScroll';
import { Wrapper, Inner } from './styles';
export interface ModalProps {
  children: ReactNode;
  onClose?: () => void;
  lockScroll?: boolean;
  closeOnEsc?: boolean;
  width?: string;
}

const ModalContainer = ({
  children,
  lockScroll,
  onClose,
  width,
}: ModalProps) => {
  const shouldClose = useRef<boolean | null>(null);
  useLockBodyScroll({ lockScroll });
  const content = useRef<HTMLDivElement>(null);
  const handleOverlayClick = useCallback(() => {
    if (shouldClose.current === null) {
      shouldClose.current = true;
    }
    if (shouldClose.current) {
      if (onClose) onClose();
    }
    shouldClose.current = null;
  }, [onClose]);
  const [handleKeyDown] = useOnCloseByEsc(content, onClose);
  const handleContentOnClick = () => {
    shouldClose.current = false;
  };

  const handleContentOnMouseDown = () => {
    shouldClose.current = false;
  };
  const handleContentOnMouseUp = () => {
    shouldClose.current = false;
  };

  return (
    <Wrapper onClick={handleOverlayClick}>
      <Inner
        $width={width}
        ref={content}
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        onMouseDown={handleContentOnMouseDown}
        onMouseUp={handleContentOnMouseUp}
        onClick={handleContentOnClick}
      >
        {children}
      </Inner>
    </Wrapper>
  );
};

export default ModalContainer;
